/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-restricted-imports */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Table,Button} from "react-bootstrap";
import {CircularProgress} from "@material-ui/core";
import ReactPaginate from 'react-paginate';
import {getIdoClaims} from '../../../../redux/MainRedux/action';
class IDOClaims extends Component {

  state = {
    claims : [],
    offset: 0,
    limit: 50,
    count: 0,
    vestId: ''
  }
  componentDidMount () {
    const id = this.props.match.params.id;
    const {offset, limit,vestId} = this.state;
    const cbSuccess = (res) => {
      console.log(res)
      this.setState({count: res.count,
        pageCount: Math.ceil(res.count / this.state.limit),})
    }
    this.props.getIdoClaims(id, this.state.offset, limit,vestId, cbSuccess);
  }

  handlePageClick = (data) => {
    const id = this.props.match.params.id;
    const {offset, limit,vestId} = this.state;
    const cbSuccess = (res) => {
        console.log(res)
        this.setState({count: res.count,
            pageCount: Math.ceil(res.count / limit),})
    window.scrollTo(0, 0)

      }
    let selected = data.selected;
    this.setState({ offset: selected}, () => {
        this.props.getIdoClaims(id,this.state.offset, limit, vestId,cbSuccess)
    });
  };
  onSearch = (value) => {
    const {offset, limit, vestId} = this.state;
    const id = this.props.match.params.id;
    const cbSuccess = (res) => {
      console.log(res)
      this.setState({count: res.count,
        pageCount: Math.ceil(res.count / this.state.limit),})
  
    }
    this.setState({offset: 0, limit: 50, vestId: value}, ()=> this.props.getIdoClaims(id,this.state.offset, limit, value,cbSuccess))

  }
  render() {
    const {count, limit} = this.state;
    const {loading, claims} = this.props;
    console.log(claims)
    return (
      <div className="row winner idos-request">
        <div className="col-md-12"> 
        <input type="search" onChange={e=> this.onSearch(e.target.value)} placeholder="Search Claims" className="form-control search" />
          
          {loading && <CircularProgress className="splash-screen-spinner" />}
          {claims && claims.claims && claims.claims.length === 0 && !loading && <h1 className='empty'>No Claim Found</h1>}
          <Table responsive>
            <thead>
              <tr>
                <th>PROJECT NAME</th>
                <th>TOKEN NAME</th>
                <th>USER ADDRESS</th>
                <th>ISCLAIMED</th>
                <th>VESTID</th>
                <th>RELEASE TIME</th>
              </tr>
            </thead>
          {claims && claims.claims && claims.claims.length === 0  && !loading && <h1 className='empty hide-visible'>No Claim Found</h1>}
            <tbody>
              {claims.length === 0 && !loading && <h1>No Claim Found</h1>}
              {claims && claims.claims && claims.claims.map(claim=> (
                <tr>
                  <td>{claim.projectName}</td>
                  <td>{claim.tokenName}</td>
                  <td>{claim.userAddress.slice(0, 8)}.....{claim.userAddress.slice(-8)}</td>
                  <td>{!claim.isClaimed ? 'YES': 'NO'}</td>
                  <td>{claim.vestId}</td>
                  <td>{new Date(claim.releaseTime * 1000).toISOString()}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {count > limit && <ReactPaginate
          previousLabel={'previous'}
          nextLabel={'next'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          forcePage={this.state.offset}
          onPageChange={this.handlePageClick}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />}
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  user: state.auth,
  claims: state.ido.claims,
  loading: state.ido.loading,
});
const mapDispatchToProps = {
    getIdoClaims
};
export default connect(mapStateToProps, mapDispatchToProps)(IDOClaims)