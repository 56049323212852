import * as TYPES from "../MainRedux/types";
const initialState = {
  idosRequests: [],
  idoRequest: null,
  ido: null,
  whiteList: [],
  claims: [],
  users: [],
  idos: [],
  winners: [],
  loading: false,
  isLoggedIn: false,
  error: null,
  isSuccess: false,
  isFailure: false,
};
const IdoReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case TYPES.GET_IDOS_REQUEST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_IDOS_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        userProfile: null,
        error: actions.data,
        isLoggedIn: false,
        isSuccess: false,
        isFailure: true,
      };
    case TYPES.GET_IDOS_REQUEST:
      return {
        ...state,
        loading: false,
        idosRequests: actions.data,
        isLoggedIn: true,
        isSuccess: true,
        isFailure: false,
      };
      case TYPES.GET_IDO_REQUEST_LOADING:
        return {
          ...state,
          loading: true,
        };
      case TYPES.GET_IDO_REQUEST_FAILURE:
        return {
          ...state,
          loading: false,
          userProfile: null,
          error: actions.data,
          isLoggedIn: false,
          isSuccess: false,
          isFailure: true,
        };
      case TYPES.GET_IDO_REQUEST:
        return {
          ...state,
          loading: false,
          idoRequest: actions.data,
          isLoggedIn: true,
          isSuccess: true,
          isFailure: false,
        };
        case TYPES.UPDATE_IDO_REQUEST_LOADING:
          return {
            ...state,
            loading: true,
          };
        case TYPES.UPDATE_IDO_REQUEST_FAILURE:
          return {
            ...state,
            loading: false,
            userProfile: null,
            error: actions.data,
            isLoggedIn: false,
            isSuccess: false,
            isFailure: true,
          };
        case TYPES.UPDATE_IDO_REQUEST:
          return {
            ...state,
            loading: false,
            isLoggedIn: true,
            isSuccess: true,
            isFailure: false,
          };
          case TYPES.CREATE_IDO_LOADING:
            return {
              ...state,
              loading: true,
            };
          case TYPES.CREATE_IDO_FAILURE:
            return {
              ...state,
              loading: false,
              userProfile: null,
              error: actions.data,
              isLoggedIn: false,
              isSuccess: false,
              isFailure: true,
            };
          case TYPES.CREATE_IDO:
            return {
              ...state,
              loading: false,
              isLoggedIn: true,
              isSuccess: true,
              isFailure: false,
            };
            case TYPES.UPDATE_IDO_LOADING:
              return {
                ...state,
                loading: true,
              };
            case TYPES.UPDATE_IDO_FAILURE:
              return {
                ...state,
                loading: false,
                userProfile: null,
                error: actions.data,
                isLoggedIn: false,
                isSuccess: false,
                isFailure: true,
              };
            case TYPES.UPDATE_IDO:
              return {
                ...state,
                loading: false,
                isLoggedIn: true,
                isSuccess: true,
                isFailure: false,
              };
           
            case TYPES.GET_IDOS_LOADING:
              return {
                ...state,
                loading: true,
              };
            case TYPES.GET_IDOS_FAILURE:
              return {
                ...state,
                loading: false,
                userProfile: null,
                error: actions.data,
                isLoggedIn: false,
                isSuccess: false,
                isFailure: true,
              };
            case TYPES.GET_IDOS:
              return {
                ...state,
                loading: false,
                idos: actions.data,
                isLoggedIn: true,
                isSuccess: true,
                isFailure: false,
              };
              case TYPES.GET_IDO_LOADING:
              return {
                ...state,
                loading: true,
              };
            case TYPES.GET_IDO_FAILURE:
              return {
                ...state,
                loading: false,
                userProfile: null,
                error: actions.data,
                isLoggedIn: false,
                isSuccess: false,
                isFailure: true,
              };
            case TYPES.GET_IDO:
              return {
                ...state,
                loading: false,
                ido: actions.data,
                isLoggedIn: true,
                isSuccess: true,
                isFailure: false,
              };
              case TYPES.GET_WHITELISTAPPLICATION_LOADING:
                return {
                  ...state,
                  loading: true,
                };
              case TYPES.GET_WHITELISTAPPLICATION_FAILURE:
                return {
                  ...state,
                  loading: false,
                  userProfile: null,
                  error: actions.data,
                  isLoggedIn: false,
                  isSuccess: false,
                  isFailure: true,
                };
              case TYPES.GET_WHITELISTAPPLICATION:
                return {
                  ...state,
                  loading: false,
                  whiteList: actions.data,
                  isLoggedIn: true,
                  isSuccess: true,
                  isFailure: false,
                };
                case TYPES.UPLOAD_IMAGE_LOADING:
                return {
                  ...state,
                  loading: true,
                };
              case TYPES.UPLOAD_IMAGE_FAILURE:
                return {
                  ...state,
                  loading: false,
                  userProfile: null,
                  error: actions.data,
                  isLoggedIn: false,
                  isSuccess: false,
                  isFailure: true,
                };
              case TYPES.UPLOAD_IMAGE:
                return {
                  ...state,
                  loading: false,
                  isLoggedIn: true,
                  isSuccess: true,
                  isFailure: false,
                };
                case TYPES.UPLOAD_WHITELIS_IMAGE_LOADING:
                  return {
                    ...state,
                    loading: true,
                  };
                case TYPES.UPLOAD_WHITELIS_IMAGE_FAILURE:
                  return {
                    ...state,
                    loading: false,
                    userProfile: null,
                    error: actions.data,
                    isLoggedIn: false,
                    isSuccess: false,
                    isFailure: true,
                  };
                case TYPES.UPLOAD_WHITELIST_IMAGE:
                  return {
                    ...state,
                    loading: false,
                    isLoggedIn: true,
                    isSuccess: true,
                    isFailure: false,
                  };
                  case TYPES.GET_IDO_WINNERS_LOADING:
                return {
                  ...state,
                  loading: true,
                };
              case TYPES.GET_IDO_WINNERS_FAILURE:
                return {
                  ...state,
                  loading: false,
                  userProfile: null,
                  error: actions.data,
                  isLoggedIn: false,
                  isSuccess: false,
                  isFailure: true,
                };
              case TYPES.GET_IDO_WINNERS:
                return {
                  ...state,
                  loading: false,
                  isLoggedIn: true,
                  winners: actions.data,
                  isSuccess: true,
                  isFailure: false,
                };
                case TYPES.DECLARE_IDO_WINNERS_LOADING:
                  return {
                    ...state,
                    loading: true,
                  };
                case TYPES.DECLARE_IDO_WINNERS_FAILURE:
                  return {
                    ...state,
                    loading: false,
                    userProfile: null,
                    error: actions.data,
                    isLoggedIn: false,
                    isSuccess: false,
                    isFailure: true,
                  };
                case TYPES.DECLARE_IDO_WINNERS:
                  return {
                    ...state,
                    loading: false,
                    isLoggedIn: true,
                    isSuccess: true,
                    isFailure: false,
                  };
                  case TYPES.GET_IDO_CLAIMS_LOADING:
                    return {
                      ...state,
                      loading: true,
                    };
                  case TYPES.GET_IDO_CLAIMS_FAILURE:
                    return {
                      ...state,
                      loading: false,
                      userProfile: null,
                      error: actions.data,
                      isLoggedIn: false,
                      isSuccess: false,
                      isFailure: true,
                    };
                  case TYPES.GET_IDO_CLAIMS:
                    return {
                      ...state,
                      loading: false,
                      claims: actions.data,
                      isLoggedIn: true,
                      isSuccess: true,
                      isFailure: false,
                    };
                    case TYPES.GET_ALL_USERS_LOADING:
                      return {
                        ...state,
                        loading: true,
                      };
                    case TYPES.GET_ALL_USERS_FAILURE:
                      return {
                        ...state,
                        loading: false,
                        userProfile: null,
                        error: actions.data,
                        isLoggedIn: false,
                        isSuccess: false,
                        isFailure: true,
                      };
                    case TYPES.GET_ALL_USERS:
                      return {
                        ...state,
                        loading: false,
                        users: actions.data,
                        isLoggedIn: true,
                        isSuccess: true,
                        isFailure: false,
                      };
        
                default:
      return state;
  }
};
export default IdoReducer;