import React, { useRef, useEffect, useState } from "react";
import { shallowEqual, useSelector, connect, useDispatch } from "react-redux";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import { withRouter } from 'react-router-dom';
import * as auth from "./authRedux";
import jwt_decode from "jwt-decode";
import { getUserByToken } from "./authCrud";
import { toast } from "react-toastify";

function AuthInit(props) {
  const didRequest = useRef(false);
  const dispatch = useDispatch();
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  // const { user } = useSelector((state) => state.auth);

  const { authToken, user } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
      user: auth.user
    }),
    shallowEqual
  );

  // We should request user by authToken before rendering the application
  useEffect(() => {

    const requestUser = async () => {
    console.log(didRequest.current)
      try {
        if (!didRequest.current) {
          console.log('ifff',didRequest.current )
          // const { data: user } = await getUserByToken();
          // console.log(user)
          dispatch(props.fulfillUser(authToken, user));
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          console.log('catch',didRequest.current )
          dispatch(props.logout());
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    if (user && user.authToken) {
      if(jwt_decode(user.authToken).exp * 1000 < Date.now()) {
        props.history.push('/logout');
        toast.error('Sesson Expire plz login again')
      }
      requestUser();
    } else {

      // dispatch(props.fulfillUser(undefined));
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>;
}

export default  withRouter(connect(null, auth.actions)(AuthInit));
