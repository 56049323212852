/* eslint-disable no-restricted-imports */
import React, { Component } from 'react';
import { Redirect, Switch, Route, Link } from "react-router-dom";
import { connect } from 'react-redux';
import Detail from './components/ido/detail';
import whiteListApplication from './components/ido/whiteListApplication';
import winners from './components/ido/winners';
import IDOClaims from './components/ido/claims';
import arrow from '../../images/arrow.svg';

class IDORequest extends Component {

  render() {
    const {location} = this.props;
    return (
      <div className="row ido">
      <div className="col-lg-6 col-sm-12">
          <Link className="back" to="/idos"><img src={arrow} alt="" />Back to all Idos</Link>
        </div>
        <div className="col-lg-6 col-sm-12 " style={{textAlign: 'end'}}>
        <ul class="nav nav-tabs">
          <li class="nav-item">
          <Link className={`${location.pathname.includes('detail') && 'active'} nav-link`} 
                  to={{pathname:`/ido/detail/${location.pathname.slice(-24)}`, state:location.state}}>Details</Link>  </li>
          <li class="nav-item">
          <Link className={`${location.pathname.includes('whitelist-application') && 'active'} nav-link`} 
                  to={{pathname:`/ido/whitelist-application/${location.pathname.slice(-24)}`, state:location.state}}>Applications</Link>
            </li>
          <li class="nav-item">
          <Link  className={`${location.pathname.includes('whitelist-winners') && 'active'} nav-link`} 
          to={{pathname:`/ido/whitelist-winners/${location.pathname.slice(-24)}`, state:location.state}}>Winners</Link>
          </li>
          <li class="nav-item">
          <Link  className={`${location.pathname.includes('claims') && 'active'} nav-link`}
           to={{pathname:`/ido/claims/${location.pathname.slice(-24)}`, state:location.state}}>Claims</Link>
          </li>
        </ul>
          
        </div>
        <div className="col-12">
          <Switch>
            <Route path="/ido/detail/:id" component={Detail} />
            <Route path="/ido/whitelist-application/:id" component={whiteListApplication} />
            <Route path="/ido/whitelist-winners/:id" component={winners} />
            <Route path="/ido/claims/:id" component={IDOClaims} />
          </Switch>
        </div>

      </div>
    )
  }
}
const mapStateToProps = state => ({
  user: state.auth,
  idoRequest: state.ido.idoRequest,
});
const mapDispatchToProps = {
 
};
export default connect(mapStateToProps, mapDispatchToProps)(IDORequest)