/* eslint-disable no-restricted-imports */
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import DateTime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import Dropzone from 'react-dropzone'
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import {CircularProgress} from "@material-ui/core";
import Select from 'react-select';
import {Button, Form, Col} from "react-bootstrap";
import {createIdo, uploadImage, uploadWhitelistImage, updateIdo} from '../../redux/MainRedux/action';

class CreateIdo extends Component {

  state = {
    validated: false,
    path: [],
    pathh: [this.props.location && this.props.location.state ? this.props.location.state.logoUrl: ''],
    path2: [],
    pathh2:this.props.location && this.props.location.state ? [this.props.location.state.whitelistingApplicationOpenImage]: [],
    isfeature: this.props.location && this.props.location.state ? this.props.location.state.isFeatured :true,
    isPublish: this.props.location && this.props.location.state ? this.props.location.state.isPublished :true,
    saleDate: this.props.location && this.props.location.state && !!this.props.location.state.saleDate ? new Date(this.props.location.state.saleDate):'',
    isProking:this.props.location && this.props.location.state ? this.props.location.state.prokingOnly : false,
    options :[
      { value: 'afghanistan', label: 'afghanistan' },
      { value: 'USA', label: 'USA' },
      { value: 'UAE', label: 'UAE' },
    ],
    tokenDistributionDate:this.props.location && this.props.location.state && !!this.props.location.state.tokenDistributionDate ? new Date(this.props.location.state.tokenDistributionDate):'',
    statusOption: [{value: 'UPCOMING', label: 'Upcoming'},{value: 'WHITELISTING_APPLICATION_OPEN', label: 'Applications Open'},
    {value: 'WHITELISTING_APPLICATION_CLOSED', label: 'Applications Closed'},{value: 'LIVE_WHITELISTING_OPEN', label: 'Live'},
  {value: '"COMPLETED"', label: 'Completed'}, {value: 'IN_PROGRESS', label: 'In Progress'}],
  accessOption: [{value: 'public', label: 'PUBLIC'},{value: 'prot', label: 'PROT'},{value: 'lottery', label: 'LOTTERY'}],
  access:[],
  accessArray: [],
  typeOption: [{value: 'GENERIC', label: 'IDO'},{value: 'GAME', label: 'IGO'}],
  type: this.props.location && this.props.location.state ? this.props.location.state.type :'',
  whitelistimagePath:'',
  status: this.props.location && this.props.location.state ? this.props.location.state.status :'',
    netowrk: this.props.location && this.props.location.state ? this.props.location.state.blockChainNetworkId :'',
    blockChain: [{value: '3', label: 'EthereumNetwork'},{value: '97', label: 'BinanceSmartChain'},{value: '3', label: 'EthereumNetwork'}, {value: '56', label: 'BinanceSmartChain'}],
    startDate:this.props.location && this.props.location.state && !!this.props.location.state.startDate ? new Date(this.props.location.state.startDate): '',
     endDate:this.props.location && this.props.location.state && !!this.props.location.state.endDate ? new Date(this.props.location.state.endDate):'',
     tokenLaunchDate: '',
     liveStartDate: this.props.location && this.props.location.state && !!this.props.location.state.liveWhitelistingStartDate ? new Date(this.props.location.state.liveWhitelistingStartDate):'',
     liveEndDate: this.props.location && this.props.location.state && !!this.props.location.state.liveWhitelistingEndDate ? new Date(this.props.location.state.liveWhitelistingEndDate):''
  }
  
  componentWillMount () {
      const {location} = this.props;
      let array =[];
      if(location && location.state) {
        for(var i=0; i< location.state.access.length; i++) {
          array.push(
            // { label: 2002, value: 2002}, {label:'dd', value: 'ds' }
            {label:location.state.access[i].toUpperCase(), value: location.state.access[i] }
            )
        }
        this.setState({accessArray: array, access: array})
      }
  }
  handleSubmit = event => {
    const {location} = this.props;
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      return this.setState({ validated: true });
    }
    if(location && location.state) {
      this.Update()
    }
    else {
      this.createNewIdo();
    }
  }
  Update = () => {
    const {pathh,pathh2,isPublish,isProking,netowrk,whitelistimagePath, access,isfeature,saleDate,type,tokenDistributionDate,
      status,tokenLaunchDate,startDate,liveEndDate, liveStartDate, endDate} = this.state;
      const {location} = this.props;
   if (pathh.length === 0) {
     return toast.error('select Logo image please');
   }
   if (pathh2.length === 0) {
     return toast.error('select whitelist  please');
   }
   if(access.length === 0 || type === ''|| status ==='' 
   || netowrk === ''  ) {
     console.log(access, saleDate, type,tokenDistributionDate, status,startDate,liveEndDate, liveStartDate,endDate,netowrk)
     return toast.error('All fields required');

   }
   const arr=[]
   access.map(ac =>arr.push(ac.value) )
  
   const cbSucessWhitelistImage = (response) => {
       const data = new FormData();
       data.append('projectLogo',pathh[0])
       const cbSuccesses =(res) => {
         const cbSuccess = (ress) => {
           if(ress.response && ress.response.status === 400) {
             console.log(ress.response.data.message)
             return toast.error(ress.response.data.message)
   
           }
           toast.success('IDO Updated');
           this.props.history.push('/idos')
         }
         console.log(res)
         this.props.updateIdo(location.state._id,
          {
           applicationId: '60b3429f0bc74f43fdfe0c36',
           name: this.Name.value,
           status,
           liveWhitelistingStartDate: !!liveStartDate ? liveStartDate.toUTCString() : null,
            liveWhitelistingEndDate: !!liveEndDate ? liveEndDate.toUTCString(): null,
            contractAddress: this.Contract.value,
            startDate: !!startDate ?startDate.toUTCString(): null,
            tokenDistributionDate:!!tokenDistributionDate ?tokenDistributionDate.toUTCString(): null,
           whitelistingApplicationOpenImage: response.whitelistingApplicationOpenImage,
           access:arr,
           minAllocationEth:this.MinimumETHAllocation.vlue,
           type,
           prokingOnly:isProking,
           saleDate:!!saleDate ? saleDate.toUTCString(): null,
            // tokenLaunchDate: tokenLaunchDate.toUTCString(),
            endDate : !!endDate ? endDate.toUTCString(): null,
           logoUrl: res.logoUrl,
           founderName: this.Founder.value,
           description: this.Description.value,
           tokenName: this.Token.value,
           tokenSymbol: this.TokenSymbol.value,
           tokenDescription: this.TokenDescription.value,
           websiteUrl: this.Website.value,
           twitterLink: this.Twitter.value,
           mediumLink: this.Medium.value,
           telegramLink: this.Telegram.value,
           telegramAnnouncementLink: this.Announce.value,
           totalRaised: this.TotalFund.value,
          //  raised: this.RaiseTill.value,
          //  tokenAllocationPerUser: this.TokenAllocation.value,
           blockChainNetworkId: this.state.netowrk,
           tokensToBeDistributed: this.tokensToBeDistributed.value,
           totalTokenSupply: this.TotalSupply.value,
           isFeatured: isfeature,
           isPublished:isPublish,
           protAllocations:this.ProtAllocations.value,
           publicAllocations: this.PublicAllocations.value,
           totalRaisedEth: this.totalEthRaised.value,
           totalRaiseEth: this.totalRaiseEth.value,
           allocations: this.allocations.value,
           minAllocation: this.minAllocation.value,
           saleEventLastSyncBlock: this.saleEventLastSyncBlock.value,
           claimEventLastSyncBlock: this.claimEventLastSyncBlock.value
   
         }, cbSuccess)
       }
       // data.append('whitelistingApplicationOpenImage',path2[0])
       this.props.uploadImage(data, cbSuccesses);
   }
   const data = new FormData();
   data.append('projectLogo',pathh2[0])
  this.props.uploadWhitelistImage(data, cbSucessWhitelistImage)
  
  }
  createNewIdo = () => {
    const {pathh,pathh2,isPublish,isProking,netowrk,whitelistimagePath, access,isfeature,saleDate,type,tokenDistributionDate,
       status,tokenLaunchDate,startDate,liveEndDate, liveStartDate, endDate} = this.state;
       console.log(pathh2)
   
       if (pathh.length === 0) {
      return toast.error('select Logo image please');
    }
  
    if(access.length === 0 || type === ''|| status ===''
    || netowrk === ''   ) {
      return toast.error('All fields required');

    }
    const arr=[]
    access.map(ac =>arr.push(ac.value) )
   
    const cbSucessWhitelistImage = (response) => {
        const data = new FormData();
        data.append('projectLogo',pathh[0])
        const cbSuccesses =(res) => {
          const cbSuccess = (ress) => {
            if(ress.response && ress.response.status === 400) {
              console.log(ress.response.data.message)
              return toast.error(ress.response.data.message)
    
            }
            toast.success('IDO created');
            this.props.history.push('/idos')
          }
          // console.log(res)
          this.props.createIdo({
            applicationId: '60b3429f0bc74f43fdfe0c36',
            name: this.Name.value,
            status,
            liveWhitelistingStartDate: !!liveStartDate ? liveStartDate.toUTCString() : null,
            liveWhitelistingEndDate: !!liveEndDate ? liveEndDate.toUTCString(): null,
            contractAddress: this.Contract.value,
            startDate: !!startDate ?startDate.toUTCString(): null,
            tokenDistributionDate:!!tokenDistributionDate ?tokenDistributionDate.toUTCString(): null,
            whitelistingApplicationOpenImage: !response ? null :response.whitelistingApplicationOpenImage,
            access:arr,
            minAllocationEth:this.MinimumETHAllocation.value,
            type,
            prokingOnly:isProking,
            saleDate:!!saleDate ? saleDate.toUTCString(): null,
            // tokenLaunchDate: tokenLaunchDate.toUTCString(),
            endDate : !!endDate ? endDate.toUTCString(): null,
            logoUrl: res.logoUrl,
            founderName: this.Founder.value,
            description: this.Description.value,
            tokenName: this.Token.value,
            tokenSymbol: this.TokenSymbol.value,
            tokenDescription: this.TokenDescription.value,
            websiteUrl: this.Website.value,
            twitterLink: this.Twitter.value,
            mediumLink: this.Medium.value,
            telegramLink: this.Telegram.value,
            telegramAnnouncementLink: this.Announce.value,
            totalRaised: this.TotalFund.value,
            // raised: this.RaiseTill.value,
            // tokenAllocationPerUser: this.TokenAllocation.value,
            blockChainNetworkId: this.state.netowrk,
            tokensToBeDistributed: this.tokensToBeDistributed.value,
            totalTokenSupply: this.TotalSupply.value,
            isFeatured: isfeature,
            isPublished:isPublish,
            protAllocations:this.ProtAllocations.value,
            publicAllocations: this.PublicAllocations.value,
            totalRaisedEth: this.totalEthRaised.value,
            totalRaiseEth: this.totalRaiseEth.value,
            allocations: this.allocations.value,
            minAllocation: this.minAllocation.value,
            saleEventLastSyncBlock: this.saleEventLastSyncBlock.value,
            claimEventLastSyncBlock: this.claimEventLastSyncBlock.value
    
          }, cbSuccess)
        }
        // data.append('whitelistingApplicationOpenImage',path2[0])
        this.props.uploadImage(data, cbSuccesses);
    }
    const data = new FormData();
    data.append('projectLogo',pathh2[0]);
    if (pathh2.length > 0) {
      this.props.uploadWhitelistImage(data, cbSucessWhitelistImage)
    }
    else {
      cbSucessWhitelistImage(false)
    }
   
  }
  getImagePath = (acceptedFiles) => {
    console.log(acceptedFiles)
    this.setState({ path: acceptedFiles, pathh: acceptedFiles.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file)
      })
    ) }, ()=>this.state.pathh);
  }
  getImagePath2 = (acceptedFiles) => {
    this.setState({ path2: acceptedFiles, pathh2: acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })) });
  }
  uploadd = () => {
    const cbSucessWhitelistImage = (r)=> {
      console.log(r.whitelistingApplicationOpenImage)
    }
    const data = new FormData();
    data.append('projectLogo',this.state.path2[0])
   this.props.uploadWhitelistImage(data, cbSucessWhitelistImage)
  }
  render() {
    const {validated, path,typeOption, accessOption, isProking,path2,liveStartDate, liveEndDate ,statusOption,
      isfeature, isPublish,blockChain, accessArray, access} = this.state;
      const {location, loading} = this.props;
      console.log(this.props.location.state)
    return (
      <div className="row create-ido">
        <div className="col-lg-8 col-sm-12">
          
            <h5>Project Details</h5>
            <Form
              noValidate
              validated={validated}
              onSubmit={e => this.handleSubmit(e)}
                >
                <Scrollbars style={ { height: 430, width: '100%' } }
                  className="scroll"
                  renderTrackHorizontal={ props => <div { ...props } className="track-horizontal"/> }
                  renderTrackVertical={ props => <div { ...props } className="track-vertical"/> }
                  >
                <Form.Row className="cat-row">
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Project Title</Form.Label>
                    <Form.Control
                      required
                      defaultValue={location && location.state && !!location.state.name && location.state.name}
                      type="text"
                      ref={ (r) => { this.Name = r; } }
                      placeholder="Project Title"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Project Website</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      defaultValue={location && location.state && !!location.state.websiteUrl && location.state.websiteUrl}
                      ref={ (r) => { this.Website = r; } }
                      placeholder="Project Website"
                    />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Founder Name</Form.Label>
                    <Form.Control
                      defaultValue={location && location.state && !!location.state.founderName && location.state.founderName}
                      type="text"
                      ref={ (r) => { this.Founder = r; } }
                      placeholder="Founder Name"
                    />
                  </Form.Group>
                     <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Contract Address</Form.Label>
                    <Form.Control
                      // required
                      defaultValue={location && location.state  && location.state.contractAddress}
                      type="text"
                      ref={ (r) => { this.Contract = r; } }
                      placeholder="Contract Address"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Contract Type</Form.Label>
                    <Select  onChange={(value)=> this.setState({status:value.value})}
                       />
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Project Type</Form.Label>
                    <Select defaultValue={location && location.state && !!location.state.type
                     && [ { value: location.state.type, label: location.state.type } ]} required onChange={(value)=> this.setState({type:value.value})}
                     options={typeOption}  />
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Status</Form.Label>
                    <Select defaultValue={location && location.state && !!location.state.status
                     && [ { value: location.state.status, label: statusOption.find(b=> b.value === location.state.status ? b.label : '' ).label } ]}  required onChange={(value)=> this.setState({status:value.value})}
                     options={statusOption}  />
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>IDO Chain Network</Form.Label>
                    <Select defaultValue={location && location.state && !!location.state.blockChainNetworkId
                     && [ { value: location.state.blockChainNetworkId, label: blockChain.find(b=> b.value === location.state.blockChainNetworkId ? b.label : '' ).label } ]} required onChange={(value)=> this.setState({netowrk:value.value})}
                     options={blockChain}  />
                  </Form.Group>
                   <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Start Date</Form.Label>
                    <DateTime required ref="datetime" 
                    initialValue={location && location.state  && !!location.state.startDate && new Date(location.state.startDate)}
                    inputProps={ {placeholder:'Start Date'} }  
                    onChange={(e)=>this.setState({startDate: new Date(e._d)}) } />
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label> End Date</Form.Label>
                    <DateTime required ref="datetime"
                    initialValue={location && location.state && !!location.state.endDate && new Date(location.state.endDate)}
                    inputProps={ {placeholder:'End Date'} } onChange={(e)=>this.setState({endDate: new Date(e._d)}) } />
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Live Whitelisting Start Date</Form.Label>
                    <DateTime ref="datetime"
                    initialValue={location && location.state && !!location.state.liveWhitelistingStartDate && new Date(location.state.liveWhitelistingStartDate)}
                    inputProps={ {placeholder:'Live Whitelisting Start Date'} } onChange={(e)=>this.setState({liveStartDate: new Date(e._d)}) } />
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Live Whitelisting End Date</Form.Label>
                    <DateTime ref="datetime" 
                    initialValue={location && location.state && !!location.state.liveWhitelistingEndDate && new Date(location.state.liveWhitelistingEndDate)}
                    inputProps={ {placeholder:'Live Whitelisting End Date'} } onChange={(e)=>this.setState({liveEndDate: new Date(e._d)}) } />
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Access</Form.Label>
                    <Select required  isMulti options={accessOption}
                    // defaultValue={ defaultAccess}
                    defaultValue={accessArray}

                    onChange={value => this.setState({access: value})}
                       />
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Form.Label>Add a Project Description</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      defaultValue={location && location.state  && location.state.description}
                      as="textarea" rows="6"
                      ref={ (r) => { this.Description = r; } }
                      placeholder="Add a Project Description"
                    />
                  </Form.Group>
                  <h5 className="col-12">Token Details</h5>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Token Name</Form.Label>
                    <Form.Control
                      required
                      defaultValue={location && location.state && !!location.state.tokenName && location.state.tokenName}
                      type="text"
                      ref={ (r) => { this.Token = r; } }
                      placeholder="e.g Prot"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Token Symbol</Form.Label>
                    <Form.Control
                      required
                      defaultValue={location && location.state && !!location.state.tokenSymbol && location.state.tokenSymbol}
                      type="text"
                      ref={ (r) => { this.TokenSymbol = r; } }
                      placeholder="e.g PROT"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Token Contract Address </Form.Label>
                    <Form.Control
                      // required
                      defaultValue={location && location.state  && location.state.tokenContractAddress}
                      type="text"
                      ref={ (r) => { this.TokenContract = r; } }
                      placeholder="e.g abd1114d.."
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Total Supply</Form.Label>
                    <Form.Control
                      // required
                      defaultValue={location && location.state  && location.state.totalTokenSupply}
                      type="number"
                      ref={ (r) => { this.TotalSupply = r; } }
                      placeholder='1,000,000'
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Token Decimal</Form.Label>
                    <Form.Control
                      required
                      defaultValue={location && location.state  && location.state.tokenDecimals}
                      type="text"
                      ref={ (r) => { this.Decimal = r; } }
                      placeholder="e.g 1.2"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Sale Date</Form.Label>
                    <DateTime ref="datetime" 
                    initialValue={location && location.state && !!location.state.saleDate && new Date(location.state.saleDate)}
                    inputProps={ {placeholder:'Sale Date'} } onChange={(e)=>this.setState({saleDate: new Date(e._d)}) } />
                  </Form.Group>
                  {/* <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Token Launch Date</Form.Label>
                    <DateTime ref="datetime" 
                    initialValue={location && location.state && !!location.state new Date(location.state.tokenLaunchDate)}
                    inputProps={ {placeholder:'Token Launch Date'} } 
                     onChange={(e)=>this.setState({tokenLaunchDate: new Date(e._d)}) } />
                  </Form.Group> */}
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Token Distributed Date</Form.Label>
                    <DateTime ref="datetime" 
                    initialValue={location && location.state && !!location.state.tokenDistributionDate && new Date(location.state.tokenDistributionDate)}
                    inputProps={ {placeholder:'Token Distributed Date'} } onChange={(e)=>this.setState({tokenDistributionDate: new Date(e._d)}) } />
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Sale Event Last SyncBlock</Form.Label>
                    <Form.Control
                      defaultValue={location && location.state  && location.state.saleEventLastSyncBlock}
                      type="number"
                      ref={ (r) => { this.saleEventLastSyncBlock = r; } }
                      placeholder="1562"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Claim Event Last Sync Block</Form.Label>
                    <Form.Control
                      defaultValue={location && location.state  && location.state.claimEventLastSyncBlock}
                      type="number"
                      ref={ (r) => { this.claimEventLastSyncBlock = r; } }
                      placeholder=" 1562"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Form.Label>Add a Token Description</Form.Label>
                    <Form.Control
                      // required
                      type="text"
                      defaultValue={location && location.state &&  location.state.tokenDescription}
                      as="textarea" rows="6"
                      ref={ (r) => { this.TokenDescription = r; } }
                      placeholder="Add a Token Description"
                    />
                  </Form.Group>
                  <h5 className="col-12">Project Social Media Handles</h5>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                  <Form.Label>Twitter Link</Form.Label>
                    <Form.Control
                      // required
                      defaultValue={location && location.state && location.state.twitterLink}
                      type="text"
                      ref={ (r) => { this.Twitter = r; } }
                      placeholder="Twitter"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                  <Form.Label>Medium Link</Form.Label>
                    <Form.Control
                      // required
                      defaultValue={location && location.state && location.state.mediumLink}
                      type="text"
                      ref={ (r) => { this.Medium = r; } }
                      placeholder="Medium"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                  <Form.Label>Telegram Link</Form.Label>
                    <Form.Control
                      // required
                      defaultValue={location && location.state && location.state.telegramLink}
                      type="text"
                      ref={ (r) => { this.Telegram = r; } }
                      placeholder="Telegram"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                  <Form.Label>Telegram Announcement Link</Form.Label>
                    <Form.Control
                      // required
                      defaultValue={location && location.state && location.state.telegramAnnouncementLink}
                      type="text"
                      ref={ (r) => { this.Announce = r; } }
                      placeholder="Announce"
                    />
                  </Form.Group>
                  <h5 className="col-12">Sale Detail</h5>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Total Raise</Form.Label>
                    <Form.Control
                      // required
                      defaultValue={location && location.state && location.state.totalRaise}
                      type="number"
                      ref={ (r) => { this.TotalFund = r; } }
                      placeholder="$1,000,000"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Total Allocation</Form.Label>
                    <Form.Control
                      defaultValue={location && location.state && location.state.allocations}
                      type="number"
                      ref={ (r) => { this.allocations = r; } }
                      placeholder="10"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Minimum  Allocation</Form.Label>
                    <Form.Control
                      defaultValue={location && location.state && location.state.minAllocation}
                      type="number"
                      ref={ (r) => { this.minAllocation = r; } }
                      placeholder="$200"
                    />
                  </Form.Group>
                  {/* <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Token Allocation Per User</Form.Label>
                    <Form.Control
                      required
                      defaultValue={location && location.state && location.state.tokenAllocationPerUser}
                      type="number"
                      ref={ (r) => { this.TokenAllocation = r; } }
                      placeholder="1500"
                    />
                  </Form.Group> */}
                  
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Prot Allocation</Form.Label>
                    <Form.Control
                      defaultValue={location && location.state && location.state.protAllocations}
                      type="number"
                      ref={ (r) => { this.ProtAllocations = r; } }
                      placeholder="200"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Public Allocation</Form.Label>
                    <Form.Control
                      defaultValue={location && location.state && location.state.publicAllocations}
                      type="number"
                      ref={ (r) => { this.PublicAllocations = r; } }
                      placeholder="200"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Total ETH Raise </Form.Label>
                    <Form.Control
                      defaultValue={location && location.state && location.state.totalRaiseEth}
                      type="number"
                      ref={ (r) => { this.totalRaiseEth = r; } }
                      placeholder="15"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Total Raised ETH</Form.Label>
                    <Form.Control
                      defaultValue={location && location.state && location.state.totalRaisedEth}
                      type="number"
                      ref={ (r) => { this.totalEthRaised = r; } }
                      placeholder="5"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Minimum ETH Allocation</Form.Label>
                    <Form.Control
                      defaultValue={location && location.state && location.state.minAllocationEth}
                      type="number"
                      ref={ (r) => { this.MinimumETHAllocation = r; } }
                      placeholder="0.01"
                    />
                  </Form.Group>
                 
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Tokens To Be Distributed</Form.Label>
                    <Form.Control
                      defaultValue={location && location.state && location.state.tokensToBeDistributed}
                      type="number"
                      ref={ (r) => { this.tokensToBeDistributed = r; } }
                      placeholder="1,000,000"
                    />
                  </Form.Group>
                  {/* <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>Total Raised</Form.Label>
                    <Form.Control
                      required
                      defaultValue={location && location.state && location.state.raised}
                      type="number"
                      ref={ (r) => { this.RaiseTill = r; } }
                      placeholder="$75,000"
                    />
                  </Form.Group> */}
                  <Form.Group as={Col} md="2" controlId="validationCustom01">
                    <Form.Label>IsFeatured</Form.Label>
                    <input checked={isfeature} onChange={e=> this.setState({isfeature: !isfeature})} className="check"
                     type="checkbox" data-toggle="toggle"/>
                  </Form.Group>
                  <Form.Group as={Col} md="2" controlId="validationCustom01">
                    <Form.Label>isPublished</Form.Label>
                    <input checked={isPublish} onChange={e=> this.setState({isPublish: !isPublish})} className="check" 
                    type="checkbox" data-toggle="toggle"/>
                  </Form.Group>
                  <Form.Group as={Col} md="2" controlId="validationCustom01">
                    <Form.Label>isProking</Form.Label>
                    <input checked={isProking} onChange={e=> this.setState({isProking: !isProking})} className="check" 
                    type="checkbox" data-toggle="toggle"/>
                  </Form.Group>
                </Form.Row>
                </Scrollbars>
                <Button disabled={loading} type="submit"
                // onClick={this.uploadd}
         >{loading ? <CircularProgress className="splash-screen-spinner" /> :location && location.state ? 'Update' : 'Save'}</Button>

              </Form>
          
        </div>
        <div className="col-lg-4 col-sm-4">
          <h5>Project Logo</h5>
          <Dropzone multiple={false} onDrop={ acceptedFiles => this.getImagePath(acceptedFiles) }
          ctiveClassName='active-dropzone'>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div className="drop-file"
                   a { ...getRootProps() }>
                  <input { ...getInputProps() } />
                  {(path.length === 0 && this.state.pathh.length === 0  )&& <p>Drag & drop image here or upload here</p>}
                  <div>{path.length > 0 ?this.state.pathh.map((file) => <img className='preview' src={file.preview} alt="" /> )
                  :<img className='preview' src={`${process.env.REACT_APP_API_URL}${location && location.state && location.state.logoUrl && location.state.logoUrl}`} alt="" />}</div>
                  {/* <h3>{path.length > 0 ? path[ 0 ].name: location && location.state ? location.state.logoUrl.slice(22,location.state.logoUrl.length): null}</h3> */}
                </div>
              </section>
                )}
          </Dropzone>
          <h5>Whitlist Image</h5>
          <Dropzone onDrop={ acceptedFiles => this.getImagePath2(acceptedFiles) }>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div className="drop-file" { ...getRootProps() }>
                  <input { ...getInputProps() } />
                  {(this.state.path2.length === 0  && this.state.pathh2.length === 0) &&<p>Drag & drop image here or upload here</p>}
                  <div>{this.state.path2.length > 0?this.state.path2.map((file) => <img  className='preview' src={file.preview} alt="" /> )
                  :<img className='preview' src={`${process.env.REACT_APP_API_URL}${location && location.state && location.state.whitelistingApplicationOpenImage&& location.state.whitelistingApplicationOpenImage}`} alt="" />}</div>
                  {/* <h3>{path2.length > 0 ? path2[ 0 ].name :location && location.state ?
                   location.state.whitelistingApplicationOpenImage.slice(22,location.state.whitelistingApplicationOpenImage.length): null}</h3> */}
                </div>
              </section>
                )}
          </Dropzone>
        </div>
        
      </div>
    )
  }
}
const mapStateToProps = state => ({
  user: state.auth,
  ido: state.ido.ido,
  loading: state.ido.loading,
});
const mapDispatchToProps = {
  createIdo,
  uploadImage,
  uploadWhitelistImage,
  updateIdo
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateIdo)