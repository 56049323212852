/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-restricted-imports */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Table} from "react-bootstrap";
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
// import Countdown from 'react-countdown';
import Checkbox from 'rc-checkbox';
import {CircularProgress} from "@material-ui/core";
import 'rc-checkbox/assets/index.css';
import {getIdos} from '../../redux/MainRedux/action';
class IDOS extends Component {

  state = {
    offset: 0,
    limit: 50,
    count: 0,
    array: [],
    networkId: '',
    ethchecked: false,
    bnbChecked: false,
    allChecked: true
  }

  componentDidMount () {
    const {offset, limit, networkId} = this.state;
    const cbSuccess = (res) => {
      console.log(res)
      this.setState({array:res.idoList,count: res.count,
        pageCount: Math.ceil(res.count / this.state.limit),})
  
    }
    this.props.getIdos(this.state.offset,limit, '',networkId, cbSuccess);
  }
  handlePageClick = (data) => {
    const {offset, limit, networkId} = this.state;
    const cbSuccess = (res) => {
        console.log(res)
        this.setState({array:res.idoList,count: res.count,
            pageCount: Math.ceil(res.count / this.state.limit),})
    window.scrollTo(0, 0)

      }
    let selected = data.selected;
    this.setState({ offset: selected,array:[]}, () => {
        this.props.getIdos( this.state.offset,limit, '',networkId,cbSuccess)
    });
  };
  onSearch = (value) => {
    const {offset, limit,name, networkId} = this.state;
    const cbSuccess = (res) => {
      console.log(res)
      this.setState({count: res.count,
        pageCount: Math.ceil(res.count / this.state.limit),})
  
    }
    this.setState({offset: 0,name:value, limit: 50}, ()=> this.props.getIdos( this.state.offset,limit, value,networkId,cbSuccess))

  }
  getByNetwork = (value) => {
    const { limit, name} = this.state;
    const cbSuccess = (res) => {
      console.log(res)
      this.setState({array:res.allIdoRequests,count: res.count,
        pageCount: Math.ceil(res.count / limit),})
  
    }
    this.setState({offset: 0, limit: 50, networkId: value}, ()=> this.props.getIdos( this.state.offset,limit, name,value,cbSuccess))

  }
  render() {
    const {loading,idos} = this.props;
    const {count, limit,networkId, allChecked, bnbChecked, ethchecked} = this.state;
    console.log(networkId)
    return (
      <div className="row idos-request">
        <div className="col-md-12"> 
          <input onChange={e=> this.onSearch(e.target.value)} type="search" placeholder="Search IDO" className="form-control search" />
          <div className="row no-gutters first-row">
            <div className="col-lg-4 col-sm-12">
              <h5 className="first-h5">Blockchain</h5>
              <div className="radio-div">
              <label>
                <Checkbox
                  id="all"
                  checked={allChecked}
                  type="checkbox"
                  onChange={(e)=>this.setState({bnbChecked:false, allChecked: true, ethchecked: false},()=> this.getByNetwork(''))}
                />
                <div>
                  All
                </div>
              </label>
              <label>
                <Checkbox
                  id="bnb"
                  type="checkbox"
                  checked={bnbChecked}
                  onChange={(e)=>this.setState({bnbChecked:true, allChecked: false, ethchecked: false},()=> this.getByNetwork(97))}
                />
                <div>
                  BNB
                </div>
              </label>
              <label>
                <Checkbox
                  id="eth"
                  checked={ethchecked}
                  type="checkbox"
                  onChange={(e)=>this.setState({bnbChecked:false, allChecked: false, ethchecked: true},()=> this.getByNetwork(3))}
                />
                <div>
                  ETH
                </div>
              </label>
             </div>
            </div>
          </div>  
          {loading && <CircularProgress className="splash-screen-spinner" />}
          <Table responsive>
            <thead>
              <tr>
                <th>PROJECT NAME</th>
                <th>PROJECT WEBSITE</th>
                <th>BLOCKCHAIN</th>
                <th>STATUS</th>
                <th>TOTAL RAISE</th>
                <th>ACTION</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {idos && idos.map(ido=>(
                <tr>
                  <td>{ido.name}</td>
                  <td><a target="_blank" rel="noreferrer"  href={ido.websiteUrl}>{ido.websiteUrl}</a></td>
                  <td>{(ido.blockChainNetworkId === '56' || ido.blockChainNetworkId === '97' ) ? 'Binance Smart Chain': 'Ethereum Network' }</td>
                  <td><span className={`badge ${(ido.status === 'UPCOMING' || ido.status === 'IN_PROGRESS') ? 'badge-primary'
                  :(ido.status === 'WHITELISTING_APPLICATION_OPEN' || ido.status === 'LIVE_WHITELISTING_OPEN' || ido.status === 'LIVE_WHITELISTING_CLOSED') ?
                  'badge-success':ido.status === 'COMPLETED' ?'badge-primary':
                  ido.status === 'LIVE_WHITELISTING_OPEN' ?'badge-success': 'badge-danger' } `} >{
                        // ido.status === "UPCOMING" && !!ido.startDate ? 
                        // <div className="count">
                        // <Countdown date={new Date(ido.startDate).getTime()} />
                        // </div> :
                        ido.status === "UPCOMING" ? 
                        'Upcoming' :
                        ido.status === "WHITELISTING_APPLICATION_OPEN" ?
                        'Applications Open': 
                        // ido.status === 'WHITELISTING_APPLICATION_CLOSED' && !!ido.liveWhitelistingStartDate ?
                        // <div className="count">
                        // <Countdown date={new Date(ido.liveWhitelistingStartDate).getTime()} />
                        // </div> :
                        ido.status === 'WHITELISTING_APPLICATION_CLOSED' ?
                        'Applications Closed' :
                        ido.status === 'LIVE_WHITELISTING_OPEN' || ido.status === 'LIVE_WHITELISTING_CLOSED' ? 
                        'Live' : 
                        ido.status === 'COMPLETED' ?
                        'Completed' : 'in progress'
                    }</span></td>
                  <td>{ido.totalRaised}</td>
                  <td><Link to={{pathname:`/ido/detail/${ido._id}`, state:{projectName: ido.name,tokenSymbol:ido.tokenSymbol}}} className="btn">View Detail</Link></td>
                  <td><Link to={{pathname:`/create-ido`, state:ido}} className="btn">Edit</Link></td>
                </tr>
              ))}
            </tbody>
          </Table>
          {count > limit && <ReactPaginate
          previousLabel={'previous'}
          nextLabel={'next'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          forcePage={this.state.offset}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />}
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  user: state.auth,
  idos: state.ido.idos,
  loading: state.ido.loading
});
const mapDispatchToProps = {
    getIdos
};
export default connect(mapStateToProps, mapDispatchToProps)(IDOS)