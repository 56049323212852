import { takeLatest, put } from "redux-saga/effects";
import * as TYPES from "../types";
import Api from "../../../app/pages/services/api";
function* getIdoRequests() {
  yield takeLatest(TYPES.GET_IDOS_REQUEST_LOADING, getIdosRequest);
  yield takeLatest(TYPES.GET_IDO_REQUEST_LOADING, getIdoRequest);
  yield takeLatest(TYPES.UPDATE_IDO_REQUEST_LOADING, updateIdoRequest);
  yield takeLatest(TYPES.CREATE_IDO_LOADING, createIdo);
  yield takeLatest(TYPES.UPDATE_IDO_LOADING, updateIdo);
  yield takeLatest(TYPES.GET_IDOS_LOADING, getIdos);
  yield takeLatest(TYPES.GET_IDO_LOADING, getIdo);
  yield takeLatest(TYPES.GET_WHITELISTAPPLICATION_LOADING, getWhiteListApliactions);
  yield takeLatest(TYPES.UPLOAD_IMAGE_LOADING, uploadImage);
  yield takeLatest(TYPES.UPLOAD_WHITELIS_IMAGE_LOADING, uploadWhitelistImage);
  yield takeLatest(TYPES.GET_IDO_WINNERS_LOADING, getIdoWinners);
  yield takeLatest(TYPES.EXPORT_IDO_WINNERS_LOADING, exportIdoWinners);
  yield takeLatest(TYPES.EMAIL_TO_IDO_WINNERS_LOADING, sendEmails);
  yield takeLatest(TYPES.DECLARE_IDO_WINNERS_LOADING, declareWinners);
  yield takeLatest(TYPES.GET_IDO_CLAIMS_LOADING, getIdoClaims);
  yield takeLatest(TYPES.GET_ALL_USERS_LOADING, getUsers);
  yield takeLatest(TYPES.GET_TOTAL_COUNTS_LOADING, getTotalCounts);
}

function* getIdosRequest(param) {
  console.log("get idos requests", param.status);
  try {
    let response = yield Api.axiosGet(`admin/ido-request/all-idorequests?offset=${param.offset}&limit=${param.limit}&projectTitle=${param.projectTitle}&status=${param.status}`, );
    console.log("response - - - >>>> ", response.allIdoRequests);
    if (response) {
      param.cbSuccess(response)
      yield put({ type: TYPES.GET_IDOS_REQUEST, data: response.allIdoRequests });
    } else {
      
      yield put({ type: TYPES.GET_IDOS_REQUEST_FAILURE, data: response });
    }
  } catch (error) {
    console.log("error from login request saga -- > > >  > ", error);
    
  }
}
function* getIdoRequest(param) {
  console.log("get idos");
  try {
    let response = yield Api.axiosGet(`admin/ido-request/single-idorequest/${param.id}`, );
    console.log("response - - - >>>> ", response.idoRequest);
    if (response) {
      yield put({ type: TYPES.GET_IDO_REQUEST, data: response.idoRequest });
    } else {
      
      yield put({ type: TYPES.GET_IDO_REQUEST_FAILURE, data: response });
    }
  } catch (error) {
    console.log("error from login request saga -- > > >  > ", error);
    
  }
}
function* updateIdoRequest(param) {
  console.log("update  ido request");
  try {
    let response = yield Api.putAxios(`admin/ido-request/${param.status}?idoRequestId=${param.id}`,param.body );
    console.log("response - - - >>>> ", response);
    if (response) {
      param.cbSuccess(response)
      yield put({ type: TYPES.UPDATE_IDO_REQUEST, data: response });
    } else {
      
      yield put({ type: TYPES.UPDATE_IDO_REQUEST_FAILURE, data: response });
    }
  } catch (error) {
    console.log("error from login request saga -- > > >  > ", error);
    
  }
}

function* updateIdo(param) {
  console.log("update  ido ");
  try {
    let response = yield Api.patchAxios(`admin/ido/update-ido?idoId=${param.id}`,param.body );
    console.log("response - - - >>>> ", response);
    if (response) {
      param.cbSuccess(response)
      yield put({ type: TYPES.UPDATE_IDO, data: response });
    } else {
      
      yield put({ type: TYPES.UPDATE_IDO_FAILURE, data: response });
    }
  } catch (error) {
    console.log("error from login request saga -- > > >  > ", error);
    
  }
}
function* createIdo(param) {
  console.log("create idos", param);
  try {
    let response = yield Api.postAxios(`admin/ido/create-ido`,param.body );
    console.log("response - - - >>>> ", response);
    if (response) {
      param.cbSuccess(response)
      yield put({ type: TYPES.CREATE_IDO, data: response });
    } else {
      
      yield put({ type: TYPES.CREATE_IDO_FAILURE, data: response });
    }
  } catch (error) {
    console.log("error from login request saga -- > > >  > ", error);
    
  }
}
function* uploadImage(param) {
  console.log("upload image ", param);
  try {
    let response = yield Api.postAxios(`admin/ido/update-logo`,param.body );
    console.log("response - - - >>>> ", response);
    if (response) {
      param.cbSuccess(response)
      yield put({ type: TYPES.UPLOAD_IMAGE, data: response });
    } else {
      
      yield put({ type: TYPES.UPLOAD_IMAGE_FAILURE, data: response });
    }
  } catch (error) {
    console.log("error from login request saga -- > > >  > ", error);
    
  }
}

function* uploadWhitelistImage(param) {
  console.log("upload whitelist image ", param);
  try {
    let response = yield Api.postAxios(`admin/ido/update-whiteliting-application-open-image`,param.body );
    console.log("response - - - >>>> ", response);
    if (response) {
      param.cbSuccess(response)
      yield put({ type: TYPES.UPLOAD_WHITELIST_IMAGE, data: response });
    } else {
      
      yield put({ type: TYPES.UPLOAD_WHITELIS_IMAGE_FAILURE, data: response });
    }
  } catch (error) {
    console.log("error from login request saga -- > > >  > ", error);
    
  }
}
function* getIdos(param) {
  console.log("get idos");
  try {
    let response = yield Api.axiosGet(`admin/ido/list-idos?offset=${param.offset}&limit=${param.limit}&name=${param.name}&networkId=${param.networkId}`, );
    console.log("response - - - >>>> ", response);
    if (response) {
      param.cbSuccess(response)
      yield put({ type: TYPES.GET_IDOS, data: response.idoList });
    } else {
      
      yield put({ type: TYPES.GET_IDOS_FAILURE, data: response });
    }
  } catch (error) {
    console.log("error from login request saga -- > > >  > ", error);
    
  }
}
function* getIdo(param) {
  console.log("get idos");
  try {
    let response = yield Api.axiosGet(`admin/ido/single-ido/${param.id}`, );
    console.log("response - - - >>>> ", response);
    if (response) {
      yield put({ type: TYPES.GET_IDO, data: response.ido });
    } else {
      
      yield put({ type: TYPES.GET_IDO_FAILURE, data: response });
    }
  } catch (error) {
    console.log("error from login request saga -- > > >  > ", error);
    
  }
}
function* getWhiteListApliactions(param) {
  console.log("get WhiteListApliactions");
  try {
    let response = yield Api.axiosGet(`admin/whitelisting/whitelisting-by-projectId/${param.id}?offset=${param.offset}&limit=${param.limit}&address=${param.search}&pool=${param.pool}`, );
    console.log("response - - - >>>> ", response);
    if (response) {
      param.cbSuccess(response);
      yield put({ type: TYPES.GET_WHITELISTAPPLICATION, data: response });
    } else {
      yield put({ type: TYPES.GET_WHITELISTAPPLICATION_FAILURE, data: response });
    }
  } catch (error) {
    console.log("error from login request saga -- > > >  > ", error);
    
  }
}
function* getIdoWinners(param) {
  console.log("get winners", param);
  try {
    let response = yield Api.axiosGet(`admin/winners//${param.id}?offset=${param.offset}&limit=${param.limit}&address=${param.search}&pool=${param.pool}`, );
    console.log("response - - - >>>> ", response);
    if (response) {
      param.cbSuccess(response);
      yield put({ type: TYPES.GET_IDO_WINNERS, data: response });
    } else {
      yield put({ type: TYPES.GET_IDO_WINNERS_FAILURE, data: response });
    }
  } catch (error) {
    console.log("error from login request saga -- > > >  > ", error);
    
  }
}

function* exportIdoWinners(param) {
  console.log("get winners", param);
  try {
    let response = yield Api.axiosGet(`admin/winners/exportWinners/${param.id}?name=${param.name}`, );
    console.log("response - - - >>>> ", response);
    if (response) {
      param.cbSuccess(response);
      yield put({ type: TYPES.EXPORT_IDO_WINNERS, data: response });
    } else {
      yield put({ type: TYPES.EXPORT_IDO_WINNERS_FAILURE, data: response });
    }
  } catch (error) {
    console.log("error from login request saga -- > > >  > ", error);
    
  }
}
function* sendEmails(param) {
  console.log("get winners", param);
  try {
    let response = yield Api.axiosPost(`admin/winners/sendEmails/${param.id}`,param.body );
    console.log("response - - - >>>> ", response);
    if (response) {
      param.cbSuccess(response);
      yield put({ type: TYPES.EMAIL_TO_IDO_WINNERS, data: response });
    } else {
      yield put({ type: TYPES.EMAIL_TO_IDO_WINNERS_FAILURE, data: response });
    }
  } catch (error) {
    console.log("error from login request saga -- > > >  > ", error);
    
  }
}

function* declareWinners(param) {
  console.log("get WhiteListApliactions");
  try {
    let response = yield Api.axiosGet(`whitelist/declare-ido-whitelisting?pool=${param.pool}&idoId=${param.id}`, );
    console.log("response - - - >>>> ", response);
    if (response) {
      param.cbSuccess(response);
      yield put({ type: TYPES.DECLARE_IDO_WINNERS, data: response });
    } else {
      yield put({ type: TYPES.DECLARE_IDO_WINNERS_FAILURE, data: response });
    }
  } catch (error) {
    console.log("error from login request saga -- > > >  > ", error);
    
  }
}
function* getIdoClaims(param) {
  console.log("get claims of ido");
  try {
    let response = yield Api.axiosGet(`admin/ido/claims/${param.id}?offset=${param.offset}&limit=${param.limit}&vestId=${param.vestId}`, );
    console.log("response - - - >>>> ", response);
    if (response) {
      param.cbSuccess(response);
      yield put({ type: TYPES.GET_IDO_CLAIMS, data: response });
    } else {
      yield put({ type: TYPES.GET_IDO_CLAIMS_FAILURE, data: response });
    }
  } catch (error) {
    console.log("error from login request saga -- > > >  > ", error);
    
  }
}
function* getTotalCounts(param) {
  console.log("get total cpounts of ido");
  try {
    let response = yield Api.axiosGet(`admin/dashboard/`, );
    console.log("response - - - >>>> ", response);
    if (response) {
      param.cbSuccess(response);
      yield put({ type: TYPES.GET_TOTAL_COUNTS, data: response });
    } else {
      yield put({ type: TYPES.GET_TOTAL_COUNTS_FAILURE, data: response });
    }
  } catch (error) {
    console.log("error from login request saga -- > > >  > ", error);
    
  }
}
function* getUsers(param) {
  console.log("get users");
  console.log(param)
  try {
    let response = yield Api.axiosGet(`admin/users?offset=${param.offset}&limit=${param.limit}&address=${param.search}`, );
    console.log("response - - - >>>> ", response);
    if (response) {
      param.cbSuccess(response);
      yield put({ type: TYPES.GET_ALL_USERS, data: response });
    } else {
      yield put({ type: TYPES.GET_ALL_USERS_FAILURE, data: response });
    }
  } catch (error) {
    console.log("error from login request saga -- > > >  > ", error);
    
  }
}
export default getIdoRequests;