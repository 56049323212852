import React, {useMemo, useEffect, useState} from "react";
import {getIdos, getUsers, getIdosRequest, getTotalCounts} from '../../../redux/MainRedux/action';
import {useDispatch, useSelector} from 'react-redux';

import {
  MixedWidget1, MixedWidget4,
} from "../widgets";
export function Demo1Dashboard() {
  const [idos, setIdos] = useState(0);
  const [users, setusers] = useState(0);
  const [idosRequests, setIidosRequests] = useState(0);
  const [claims, setclaims] = useState(0);
  const [application, setapplication] = useState(0);
  const [winners, setwinners] = useState(0);
  const dispatch = useDispatch();
  const sbSuccess = (re)=> {
    console.log(re)
    setIdos(re.count)
  }
  const sbSuccesss = (re)=> {
    console.log(re)
    setusers(re.count)
  }
  const sbSuccessss = (re)=> {
    console.log(re)
    setIidosRequests(re.count)
  }
  const cbSuccess = (res) => {
    console.log(res)
    setapplication(res.application)
    setclaims(res.claims)
    setwinners(res.winners)
  }
  useEffect(() => {
    dispatch(getIdos(0,50,'','',sbSuccess))
    dispatch(getUsers(0, 50, '', sbSuccesss))
    dispatch(getIdosRequest( 0, 50, '',[], sbSuccessss))
    dispatch(getTotalCounts(cbSuccess))
   
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-xxl-10">
          <MixedWidget1 first='IDOs' fInfo={idos} seInfo={users} thInfo={idosRequests} foInfo={application}
          sec="Users" third='Requests' four="Applications"
          five="Winners" fiveInfo={winners} six="Claims" sixInfo={claims} className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-6 col-xxl-4">
        {/* <MixedWidget1 first='Winners' fInfo={winners} seInfo={claims}
        //  thInfo={idosRequests} foInfo={application}
          sec="Claims"
          //  third='Requests' four="Applications" 
           className="card-stretch gutter-b" /> */}
        
        </div>
        {/* <div className="col-lg-6 col-xxl-4">
          <ListsWidget9 className="card-stretch gutter-b" />
        </div> */}

        {/* <div className="col-lg-6 col-xxl-4">
          <StatsWidget11
            className="card-stretch card-stretch-half gutter-b"
            symbolShape="circle"
            baseColor="success"
          />
          <StatsWidget12 className="card-stretch card-stretch-half gutter-b" />
        </div> */}

        {/* <div className="col-lg-6 col-xxl-4 order-1 order-xxl-1">
          <ListsWidget1 className="card-stretch gutter-b" />
        </div>
        <div className="col-xxl-8 order-2 order-xxl-1">
          <AdvanceTablesWidget2 className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
          <ListsWidget3 className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
          <ListsWidget4 className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-12 col-xxl-4 order-1 order-xxl-2">
          <ListsWidget8 className="card-stretch gutter-b" />
        </div> */}
      </div>
      {/* <div className="row">
        <div className="col-lg-4">
          <MixedWidget14 className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-8">
          <AdvanceTablesWidget4 className="card-stretch gutter-b" />
        </div>
      </div> */}
    </>
  );
}
