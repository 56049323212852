export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { user }
      } = store.getState();
      if (user) {
        config.headers.Authorization = `${user.authToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );
}
