export const GET_IDOS_REQUEST = "GET_IDOS_REQUEST";
export const GET_IDOS_REQUEST_LOADING = "GET_IDOS_REQUEST_LOADING";
export const GET_IDOS_REQUEST_FAILURE = "GET_IDOS_REQUEST_FAILURE";

export const GET_IDO_REQUEST = "GET_IDO_REQUEST";
export const GET_IDO_REQUEST_LOADING = "GET_IDO_REQUEST_LOADING";
export const GET_IDO_REQUEST_FAILURE = "GET_IDO_REQUEST_FAILURE";

export const UPDATE_IDO_REQUEST = "UPDATE_IDO_REQUEST";
export const UPDATE_IDO_REQUEST_LOADING = "UPDATE_IDO_REQUEST_LOADING";
export const UPDATE_IDO_REQUEST_FAILURE = "UPDATE_IDO_REQUEST_FAILURE";

export const CREATE_IDO = "CREATE_IDO"
export const CREATE_IDO_LOADING = "CREATE_IDO_LOADING"
export const CREATE_IDO_FAILURE = "CREATE_IDO_FAILURE"

export const UPDATE_IDO = "UPDATE_IDO";
export const UPDATE_IDO_LOADING = "UPDATE_IDO_LOADING";
export const UPDATE_IDO_FAILURE = "UPDATE_IDO_FAILURE";

export const GET_IDOS = "GET_IDOS";
export const GET_IDOS_LOADING = "GET_IDOS_LOADING";
export const GET_IDOS_FAILURE = "GET_IDOS_FAILURE"

export const GET_IDO = "GET_IDO";
export const GET_IDO_LOADING = "GET_IDO_LOADING";
export const GET_IDO_FAILURE = "GET_IDO_FAILURE"

export const GET_WHITELISTAPPLICATION = "GET_WHITELISTAPPLICATION";
export const GET_WHITELISTAPPLICATION_LOADING = "GET_WHITELISTAPPLICATION_LOADING";
export const GET_WHITELISTAPPLICATION_FAILURE = "GET_WHITELISTAPPLICATION_FAILURE"

export const GET_IDO_WINNERS = "GET_IDO_WINNERS";
export const GET_IDO_WINNERS_LOADING = "GET_IDO_WINNERS_LOADING";
export const GET_IDO_WINNERS_FAILURE = "GET_IDO_WINNERS_FAILURE"

export const EXPORT_IDO_WINNERS = "EXPORT_IDO_WINNERS";
export const EXPORT_IDO_WINNERS_LOADING = "EXPORT_IDO_WINNERS_LOADING";
export const EXPORT_IDO_WINNERS_FAILURE = "EXPORT_IDO_WINNERS_FAILURE"

export const EMAIL_TO_IDO_WINNERS = "EMAIL_TO_IDO_WINNERS";
export const EMAIL_TO_IDO_WINNERS_LOADING = "EMAIL_TO_IDO_WINNERS_LOADING";
export const EMAIL_TO_IDO_WINNERS_FAILURE = "EMAIL_TO_IDO_WINNERS_FAILURE"

export const GET_TOTAL_COUNTS = "GET_TOTAL_COUNTS";
export const GET_TOTAL_COUNTS_LOADING = "GET_TOTAL_COUNTS_LOADING";
export const GET_TOTAL_COUNTS_FAILURE = "GET_TOTAL_COUNTS_FAILURE"

export const DECLARE_IDO_WINNERS = "DECLARE_IDO_WINNERS";
export const DECLARE_IDO_WINNERS_LOADING = "DECLARE_IDO_WINNERS_LOADING";
export const DECLARE_IDO_WINNERS_FAILURE = "DECLARE_IDO_WINNERS_FAILURE"

export const GET_IDO_CLAIMS = "GET_IDO_CLAIMS";
export const GET_IDO_CLAIMS_LOADING = "GET_IDO_CLAIMS_LOADING";
export const GET_IDO_CLAIMS_FAILURE = "GET_IDO_CLAIMS_FAILURE"

export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_USERS_LOADING = "GET_ALL_USERS_LOADING";
export const GET_ALL_USERS_FAILURE = "GET_ALL_USERS_FAILURE"

export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const UPLOAD_IMAGE_LOADING = "UPLOAD_IMAGE_LOADING";
export const UPLOAD_IMAGE_FAILURE = "UPLOAD_IMAGE_FAILURE"

export const UPLOAD_WHITELIST_IMAGE = "UPLOAD_WHITELIS_IMAGE";
export const UPLOAD_WHITELIS_IMAGE_LOADING = "UPLOAD_WHITELIS_IMAGE_LOADING";
export const UPLOAD_WHITELIS_IMAGE_FAILURE = "UPLOAD_WHITELIS_IMAGE_FAILURE"