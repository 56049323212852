/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-restricted-imports */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Table, Dropdown} from "react-bootstrap";
import ReactPaginate from 'react-paginate';
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import {CircularProgress} from "@material-ui/core";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import {getIdoWinners, declareWinners, exportIdoWinners, sendEmails} from '../../../../redux/MainRedux/action';
import { toast } from 'react-toastify';
import ReactExport from "react-export-excel";
import fileDownload from 'js-file-download';
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import axios from 'axios'
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const dataSet1 = [
  {
      name: "Johson",
      amount: 30000,
      sex: 'M',
      is_married: true
  },
  {
      name: "Monika",
      amount: 355000,
      sex: 'F',
      is_married: false
  },
  {
      name: "John",
      amount: 250000,
      sex: 'M',
      is_married: false
  },
  {
      name: "Josef",
      amount: 450500,
      sex: 'M',
      is_married: true
  }
];

class Winners extends Component {

  state = {
    winners : [],
    offset: 0,
    limit: 100,
    count: 0,
    search: '',
    pool: '',
    publicProt:false,
    allChecked:true,
    prot: false,
    isTrue:false,
    laoder: false
     }
  componentDidMount () {
    const id = this.props.match.params.id;
    const cbSuccess = (res) => {
      console.log(res)
      this.setState({winners: res.winners,count: res.count,
        pageCount: Math.ceil(res.count / this.state.limit),})
  
    }
    this.props.getIdoWinners(id, this.state.offset, this.state.limit,this.state.search,this.state.pool,cbSuccess);
  }
  handlePageClick = (data) => {
    const id = this.props.match.params.id;
    const cbSuccess = (res) => {
        console.log(res)
        this.setState({winners: res.winners,count: res.count,
            pageCount: Math.ceil(res.count / this.state.limit),})
    window.scrollTo(0, 0)

      }
    let selected = data.selected;
    this.setState({ offset: selected}, () => {
        this.props.getIdoWinners(id, this.state.offset, this.state.limit,this.state.search,cbSuccess)
    });
  };
  submit = () => {
    confirmAlert({
      title: 'Confirm to Send Emails',
      message: 'Are you sure to send email to winners.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.sendEmailsToWinners()
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  };

  getByPool = (value) => {
    const {offset, limit,pool} = this.state;
    const id = this.props.match.params.id;
    const cbSuccess = (res) => {
      console.log(res)
      this.setState({winners: res.winners,count: res.count,
        pageCount: Math.ceil(res.count / limit),})
  
    }
    this.setState({offset: 0, limit: 50, pool: value}, ()=> this.props.getIdoWinners(id, this.state.offset,limit, this.state.search,value,cbSuccess))

  }
  onDeclare = (pool)=> {
    const {declareWinners, match} = this.props;
    const id =match.params.id;
    const cbSuccess = (res) => {
      console.log(res);
      if(res.success === 1) {
        toast.success(`${pool} winners declared`)
      }
      const cbSuccesss = (res) => {
        console.log(res)
        this.setState({winners: res.winners,count: res.count,
          pageCount: Math.ceil(res.count / this.state.limit),})
    
      }
      this.props.getIdoWinners(id, this.state.offset, this.state.limit,this.state.search,this.state.pool,cbSuccesss);
    }
    declareWinners(id, pool, cbSuccess)
  }
  onSearch = (value) => {
    const { limit, offset} = this.state;
    const { match} = this.props;
    const id =match.params.id;
    const cbSuccess = (res) => {
      console.log(res)
      this.setState({winners:res.winners,count: res.count,
        pageCount: Math.ceil(res.count / this.state.limit),})
  
    }
    this.setState({offset: 0, limit: 50, search:value},
       ()=> this.props.getIdoWinners(id,this.state.offset,limit, value,this.state.pool,cbSuccess))

  }
  exportWinners = ()=> {
    const {match,user,location} = this.props;
    const id = match.params.id;
    const name = location && location.state && location.state.projectName;
    var token =  user.user.authToken;
    var url = `${process.env.REACT_APP_API_URL}admin/winners/exportWinners/${id}?name=${name}`;  
    // var url = `https://devapi.prostarter.io/admin/winners/exportWinners/${id}?name=${name}`;  
    axios.get(url,{
      responseType: 'arraybuffer',
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then(response=>{
       toast.success('Winners file exporting...');
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      console.log(response.config.url)
      link.setAttribute('download', `${name}-winners.xlsx`);
      document.body.appendChild(link);
      link.click();
      this.setState({loader:false})
    } )
    .catch(err=>{
      toast.error("Some error occur")
    })

  }
  getSheetData =(data, header) => {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : "";
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }

  sendEmailsToWinners = () => {
    const {match,location} = this.props;
    const id = match.params.id;
    const projectName = location && location.state && location.state.projectName;
    const tokenSymbol = location && location.state && location.state.tokenSymbol;
    const cbSuccess = (res) => {
      console.log(res)
      toast.success('Sending Emails')
    }
    this.props.sendEmails(id,{projectName,tokenSymbol},cbSuccess)
  }
  // exportToExcel = (apiData, fileName ) => {
  //   let header = ["Address"];
  //   XlsxPopulate.fromBlankAsync().then(async (workbook) => {
  //     const sheet1 = workbook.sheet(0);
  //     const sheetData = this.getSheetData(apiData, header);
  //     const totalColumns = sheetData[0].length;

  //     sheet1.cell("A1").value(sheetData);
  //     const range = sheet1.usedRange();
  //     const endColumn = String.fromCharCode(64 + totalColumns);
  //     sheet1.row(1).style("bold", true);
  //     sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
  //     range.style("border", true);
  //     return workbook.outputAsync().then((res) => {
  //     toast.success('File Downloaded')
  //       saveAs(res, `${fileName}.xlsx`);
  //     });
  //   });
  // }
  render() {
    const {count,winners,limit, allChecked,prot, publicProt, isTrue} = this.state;
    const {loading} = this.props;
    return (
      <div className="row idos-request winner">
        <div className="col-md-12"> 
        <input  onChange={e=> this.onSearch(e.target.value)} type="search"
         placeholder="Search" className="form-control search" />
          <div className="row no-gutters first-row">
            <div className="col-4">
              <h5 className="first-h5">Pool</h5>
              <div className="radio-div">
              <label>
                <Checkbox
                  id="all"
                  checked={allChecked}
                  type="checkbox"
                  onChange={(e)=>this.setState({publicProt:false, allChecked: true, prot: false},()=> this.getByPool(''))}
                />
                <div>
                  All
                </div>
              </label>
              <label>
                <Checkbox
                  id="bnb"
                  type="checkbox"
                  checked={prot}
                  onChange={(e)=>this.setState({prot:true, allChecked: false, publicProt: false},()=> this.getByPool('prot'))}
                />
                <div>
                  PROT
                </div>
              </label>
              <label>
                <Checkbox
                  id="eth"
                  checked={publicProt}
                  type="checkbox"
                  onChange={(e)=>this.setState({prot:false, allChecked: false, publicProt: true},()=> this.getByPool('public'))}
                />
                <div>
                  PUBLIC
                </div>
              </label>
             </div>
            </div>
            <div className='col-lg-4'></div>
            <div className='col-lg-4'>
            <h5 className="hide first-h5">Pool</h5>

              <div className='action-div'>
              <button onClick={this.submit} className='btn'>Send Email to Winners</button>
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Action
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={()=>this.onDeclare('prot')} >Declare Prot Winners</Dropdown.Item>
                <Dropdown.Item onClick={()=>this.onDeclare('public')} >Declare Public Winners</Dropdown.Item>
                <Dropdown.Item onClick={this.exportWinners} >Exports</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            </div>
              {/* <label>Action</label>
              <Select
              options={options}
              onChange={e=> this.onSelect(e.value)} /> */}
            </div>
          </div>   
          {loading && <CircularProgress className="splash-screen-spinner" />}
          {winners.length === 0 && !loading && <h1 className='empty'>No Winner Found</h1>}

          <Table responsive>
            <thead>
              <tr>
                <th>ADDRESS</th>
                <th>POOL</th>
                <th>LOTTERY NUMBER</th>
                <th>PROKING STATUS</th>
              </tr>
            </thead>
            {winners.length === 0 && !loading && <h1 className='hide-visible'>No Winner Found</h1>}

            <tbody>
              {winners.map(winner=> (
                <tr>
                  <td>{winner.address} </td>
                  <td>{winner.pool}</td>
                  <td>{winner.lotteryNum}</td>
                  <td>{winner.prokingStatus ? 'True': 'False'}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {count > limit && <ReactPaginate
          previousLabel={'previous'}
          nextLabel={'next'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick}
          forcePage={this.state.offset}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />}
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  user: state.auth,
  winners: state.ido.winners,
  loading: state.ido.loading
});
const mapDispatchToProps = {
    getIdoWinners,
    declareWinners,
    exportIdoWinners,
    sendEmails
};
export default connect(mapStateToProps, mapDispatchToProps)(Winners)