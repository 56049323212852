/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-restricted-imports */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Table} from "react-bootstrap";
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import {CircularProgress} from "@material-ui/core";

import moment from 'moment';
import {getIdosRequest} from '../../redux/MainRedux/action';
class IDOSRequests extends Component {

  state = {
    offset: 0,
    limit: 50,
    count: 0,
    array: [],
    networkId: '',
    ethchecked: false,
    bnbChecked: false,
    allChecked: true,
    access: [],
    accessOption: [{value: 'APPROVED',label: 'APPROVED'}, {value: 'PENDING',label: 'PENDING'}, {value: 'REJECTED',label: 'REJECTED'}]
  }
  componentDidMount () {
    const {offset, limit, access} = this.state;
    const cbSuccess = (res) => {
      console.log(res)
      this.setState({array:res.allIdoRequests, count: res.count,
        pageCount: Math.ceil(res.count / this.state.limit),})
  
    }
    this.props.getIdosRequest(this.state.offset, limit,'',access, cbSuccess);
  }
  handlePageClick = (data) => {
    const {offset, limit, access} = this.state;
    const cbSuccess = (res) => {
        console.log(res)
        this.setState({array:res.allIdoRequests,count: res.count,
            pageCount: Math.ceil(res.count / this.state.limit),})
    window.scrollTo(0, 0)

      }
    let selected = data.selected;
    this.setState({ offset: selected,array:[]}, () => {
        this.props.getIdosRequest( this.state.offset, limit, '',access,cbSuccess)
    });
  };

  onSearch = (value) => {
    const {offset, limit, access} = this.state;
    const cbSuccess = (res) => {
      console.log(res)
      this.setState({array:res.allIdoRequests,count: res.count,
        pageCount: Math.ceil(res.count / this.state.limit),})
  
    }
    this.setState({offset: 0, limit: 50}, ()=> this.props.getIdosRequest(this.state.offset, limit, value,access,cbSuccess))
  }
  getByStatus = () => {
    const {offset, limit,access} = this.state;
    const cbSuccess = (res) => {
      console.log(res)
      this.setState({array:res.allIdoRequests,count: res.count,
        pageCount: Math.ceil(res.count / limit),})
  
    }
    this.setState({offset: 0, limit: 50}, ()=> this.props.getIdosRequest( this.state.offset,limit, '',access,cbSuccess))

  }
  render() {
    const {idosRequests, loading} = this.props;
    const {count, limit, accessOption, access, ethchecked} = this.state;
    console.log(access)
    return (
      <div className="row idos-request">
        <div className="col-md-12"> 
          <input onChange={e=> this.onSearch(e.target.value)} type="search" placeholder="Search IDO requests" className="form-control search" />
          <div className="row no-gutters first-row">
            <div className="col-lg-3 col-sm-12">
            </div>
            <div className="col-lg-9 col-sm-12"></div>
           
            <div className="col-lg-5 col-sm-12">
              <h5 className="first-h5">Status</h5>
              <Select required  isMulti options={accessOption}
                    onChange={value => this.setState({access: value ? value.map(v => v.value):[]},()=>this.getByStatus())}
                       />
            </div>
          </div>       
          {loading && <CircularProgress className="splash-screen-spinner" />}
          <Table responsive className='mt-5'>
            <thead>
              <tr>
                <th>PROJECT NAME</th>
                <th>PROJECT WEBSITE</th>
                <th>BLOCKCHAIN</th>
                <th>TGE DATE</th>
                <th>REQUEST DATE</th>
                <th>STATUS</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {idosRequests && idosRequests.map(ido=>(
                <tr>
                  <td>{ido.projectTitle}</td>
                  <td><a target="_blank" rel="noreferrer"  href={ido.websiteUrl}>{ido.websiteUrl}</a></td>
                  <td>{ido.IDOChainNetwork}</td>
                  <td>{moment(ido.tokenLaunchDate).format("MMM Do YY")}</td>
                  <td>{moment(ido.createdAt).format("MMM Do YY")}</td>
                  <td><span className={`badge ${ido.status.toLowerCase() === 'pending' ? 'badge-primary':ido.status.toLowerCase() === 'approved' ?
                  'badge-success':'badge-danger' } `} >{ido.status.toUpperCase()}</span></td>
                  <td><Link to={`/ido-request/${ido._id}`} className="btn">View Detail</Link></td>
                </tr>
              ))}
            </tbody>
          </Table>
          {count > limit && <ReactPaginate
          previousLabel={'previous'}
          nextLabel={'next'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          forcePage={this.state.offset}
          onPageChange={this.handlePageClick}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />}
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  user: state.auth,
  idosRequests: state.ido.idosRequests,
  loading: state.ido.loading,
});
const mapDispatchToProps = {
  getIdosRequest
};
export default connect(mapStateToProps, mapDispatchToProps)(IDOSRequests)