/* eslint-disable no-restricted-imports */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {Card, Button} from "react-bootstrap";
import {CircularProgress} from "@material-ui/core";
import moment from 'moment';
import {getIdo,} from '../../../../redux/MainRedux/action';
import placeholder from '../../../../images/placholder.svg';

class Detail extends Component {

  componentDidMount () {
    this.props.getIdo(this.props.match.params.id);
  }
  addDefaultSrc(ev){
    ev.target.src = placeholder
  }
  render() {
    const {ido, loading} = this.props;
    return (
      <div className="row ido-request">
      {loading && <CircularProgress className="splash-screen-spinner" />}
      {ido && <>
        <div className="col-lg-4 col-xl-3 col-sm-12"> 
        <Card style={{ width: '20rem' }}>
          <Card.Img className='logo-img' onError={this.addDefaultSrc} variant="top" src={`${!!ido.logoUrl ?
             process.env.REACT_APP_API_URL + ido.logoUrl: placeholder}`} />
          <Card.Body>
            <Card.Title>{ido.name}</Card.Title>
            <Card.Title className="detail-heading">Project Detail</Card.Title>
            <div className="info-div">
              <p>Project Website</p>
              <span>{ido.websiteUrl}</span>
            </div>
            <div className="info-div">
                <p>Status</p>
                <san>{ido.status === "UPCOMING" ? 
                        'Upcoming' :
                        ido.status === "WHITELISTING_APPLICATION_OPEN" ?
                        'Applications Open': 
                        // ido.status === 'WHITELISTING_APPLICATION_CLOSED' && !!ido.liveWhitelistingStartDate ?
                        // <div className="count">
                        // <Countdown date={new Date(ido.liveWhitelistingStartDate).getTime()} />
                        // </div> :
                        ido.status === 'WHITELISTING_APPLICATION_CLOSED' ?
                        'Applications Closed' :
                        ido.status === 'LIVE_WHITELISTING_OPEN' || ido.status === 'LIVE_WHITELISTING_CLOSED' ? 
                        'Live' : 
                        ido.status === 'COMPLETED' ?
                        'Completed' : 'in progress'}</san>
              </div>
              <div className="info-div">
              <p>Blockchain</p>
              <span>{(ido.blockChainNetworkId === '56' || ido.blockChainNetworkId === '97' ) ? 'Binance Smart Chain': 'Ethereum Network'  }</span>
            </div>
            
            <div className="info-div">
                <p>Contract Address </p>
                <span>{!!ido.contractAddress ? ido.contractAddress: '-'}</span>
              </div>
              <div className="info-div">
                <p>Founder Name</p>
                <span>{!!ido.founderName ? ido.founderName: '-'}</span>
              </div>
           
          </Card.Body>
        </Card>
        <Card  style={{ width: '20rem',marginTop:'1rem' }}>
         <Card.Body>
            <Card.Title className="detail-heading">Token Detail</Card.Title>
            <div className="info-div">
              <p>Token Name</p>
              <span>{ido.tokenName}</span>
            </div>
            <div className="info-div">
              <p>Token Symbol</p>
              <span>{ido.tokenSymbol}</span>
            </div>
            <div className="info-div">
              <p>Token Address</p>
              <span>{!!ido.tokenContractAddress ? ido.tokenContractAddress: '-'}</span>
            </div>
           
          </Card.Body>
        </Card>
        <Card style={{ width: '20rem', marginTop: '1rem' }}>
          <Card.Body>
            <Card.Title className="detail-heading">Social Media</Card.Title>
            <div className="info-div">
              <p>Twitter</p>
              <span>{!!ido.twitterLink ? ido.twitterLink : '-'}</span>
            </div>
            <div className="info-div">
              <p>Telegram</p>
              <span>{!!ido.telegramLink ? ido.telegramLink: '-'}</span>
            </div>
            <div className="info-div">
              <p>Telegram</p>
              <span>{!!ido.telegramAnnouncementLink ? ido.telegramAnnouncementLink: '-' }</span>
            </div>
          </Card.Body>
        </Card>
        </div>
        <div className="col-lg-8 col-xl-9 col-sm-12">
          <div className="project-description">
            <Card.Title className="detail-heading">Project Description</Card.Title>
            <p>{ido.description}</p>
            <Card.Title className="detail-heading">Token Description</Card.Title>
            <p>{ido.tokenDescription}</p>
            
          </div>  
          <div className="bottom-div">
            {/* <div className="sub-bottom">
              <Card.Title className="detail-heading">Funding Details</Card.Title>
              <div>
                <p>Total Fund Raise</p>
                <span>{!!ido.totalFundRaise ? ido.totalFundRaise: '-'}</span>
              </div>
              <div>
                <p>Total Supply</p>
                <span>{!!ido.totalTokenSupply? ido.totalTokenSupply: '-'}</span>
              </div>
              <div>
                <p>Total Raised till now</p>
                <span>{!!ido.totalRaise ? ido.totalRaise: '-'}</span>
              </div>
              <div>
                <p>Allocations</p>
                <span>{!!ido.allocations ? ido.allocations: '-'}</span>
              </div>
            </div> */}
            <div className='sale-div'>
            <Card.Title className="detail-heading">Sale Detail</Card.Title>
             <div className='sub-sale'>
              <div>
                <p>Minimum Allocation</p>
                <span>{!!ido.minAllocation ?ido.minAllocation: '-'}</span>
                <p>Token Allocation Per User</p>
                <span>{!!ido.tokenAllocationPerUser ?ido.tokenAllocationPerUser: '-'}</span>
                <p>Prot Allocation</p>
                <span>{!!ido.protAllocations ?ido.protAllocations: '-'}</span>
                <p>Public Allocation</p>
                <span>{!!ido.protAllocations ?ido.publicAllocations: '-'}</span>
              </div>
              <div>
              <p>Total ETH Raise</p>
                <span>{!!ido.totalRaiseEth ?ido.totalRaiseEth: '-'}</span>
                <p>Total Raised ETH</p>
                <span>{!!ido.totalRaisedEth ?ido.totalRaisedEth: '-'}</span>
                <p>Minimum ETH Allocation</p>
                <span>{!!ido.minAllocationEth ?ido.minAllocationEth: '-'}</span>
                <p>Tokens To Be Distributed</p>
                <span>{!!ido.tokensToBeDistributed ?ido.tokensToBeDistributed: '-'}</span>
              </div>
              </div>
            </div>
            <div className="sub-bottom last">
              <Card.Title className="detail-heading">Date Detail</Card.Title>
              <div>
                <p>Start Date</p>
                <span>{!!ido.startDate ? moment(ido.startDate).format('MMMM Do YYYY, h:mm:ss a'): '-'}</span>
              </div>
              <div>
                <p>End Date</p>
                <span>{!!ido.endDate ? moment(ido.endDate).format('MMMM Do YYYY, h:mm:ss a'): '-' }</span>
              </div>
              <div>
                <p>Live Whitelisting Start Date</p>
                <span>{!!ido.liveWhitelistingStartDate ? moment(ido.liveWhitelistingStartDate).format('MMMM Do YYYY, h:mm:ss a') : '-'}</span>
              </div>
              <div>
                <p>Live Whitelisting End Date</p>
                <span>{!!ido.liveWhitelistingEndDate ? moment(ido.liveWhitelistingEndDate).format('MMMM Do YYYY, h:mm:ss a') : '-'}</span>
              </div>
              <div>
                <p>Sale Date</p>
                <span>{!!ido.saleDate ? moment(ido.saleDate).format('MMMM Do YYYY, h:mm:ss a') : '-'}</span>
              </div>
              <div>
                <p>Token Distributed Date</p>
                <span>{!!ido.tokenDistributionDate ? moment(ido.tokenDistributionDate).format('MMMM Do YYYY, h:mm:ss a') : '-'}</span>
              </div>
            </div>
          
          </div>
        </div> </>}
      </div>
    )
  }
}
const mapStateToProps = state => ({
  user: state.auth,
  loading: state.ido.loading,
  ido: state.ido.ido,
});
const mapDispatchToProps = {
    getIdo
};
export default connect(mapStateToProps, mapDispatchToProps)(Detail)