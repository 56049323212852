/* eslint-disable no-restricted-imports */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {Card, Button} from "react-bootstrap";
import moment from 'moment';
import {getIdoRquest, updatetIdoRquest} from '../../redux/MainRedux/action';
import placeholder from '../../images/placholder.svg';
import arrow from '../../images/arrow.svg';

class IDORequest extends Component {

  componentDidMount () {
  
    this.props.getIdoRquest(this.props.match.params.id);
  }

  update = (status) => {
    
    const cbSuccess = (res) => {
    this.props.getIdoRquest(this.props.match.params.id);

    }
    this.props.updatetIdoRquest(this.props.match.params.id,status, {reason: 'some reason'},cbSuccess);
  }
  addDefaultSrc(ev){
    ev.target.src = placeholder
  }
  render() {
    const {idoRequest} = this.props;
    return (
      <div className="row ido-request">
        {idoRequest && <><div className="col-lg-6 col-sm-12">
          <Link className="back" to="/idos-requests"><img src={arrow} alt="" /> Back to all Ido Requests</Link>
        </div>
       <div className="col-lg-6 col-sm-12 status-div">
       {idoRequest.status === "PENDING" && <> <Button onClick={()=>this.update('reject')}>Reject</Button>
          <Button onClick={()=>this.update('approve')}>Approve</Button></>}
        </div>
        <div className="col-lg-4 col-xl-3 col-sm-12"> 
        <Card style={{ width: '20rem' }}>
          <Card.Img  className='logo-img' onError={this.addDefaultSrc}
          variant="top" src={`${process.env.REACT_APP_API_URL}${idoRequest.projectLogo}`} />
          <Card.Body>
            <Card.Title>{idoRequest.projectTitle}</Card.Title>
            <Card.Title className="detail-heading">Project Detail</Card.Title>
            <div className="info-div">
              <p>TOKEN NAME</p>
              <span>{idoRequest.tokenName}</span>
            </div>
            <div className="info-div">
              <p>TOKEN SYMBOL</p>
              <span>{idoRequest.tokenSymbol}</span>
            </div>
            <div className="info-div">
              <p>BLOCKCHAIN</p>
              <span>{idoRequest.IDOChainNetwork === 'EthereumNetwork'? 'ETH': 'BNB'}</span>
            </div>
            <div className="info-div">
              <p>STATUS</p>
              <span>{idoRequest.status}</span>
            </div>
            <div className="info-div">
              <p>TGE</p>
              <span>{moment(idoRequest.tokenLaunchDate).format("MMM Do YY")}</span>
            </div>
          </Card.Body>
        </Card>
        <Card style={{ width: '20rem', marginTop: '1rem' }}>
          <Card.Body>
            <Card.Title className="detail-heading">Social Media</Card.Title>
            <div className="info-div">
              <p>Twitter</p>
              <span>{idoRequest.projectTwitter}</span>
            </div>
            <div className="info-div">
              <p>Telegram</p>
              <span>{idoRequest.projectTelegram}</span>
            </div>
            <div className="info-div">
              <p>Telegram</p>
              <span>{idoRequest.founderTelegram }</span>
            </div>
          </Card.Body>
        </Card>
        </div>
        <div className="col-lg-8 col-xl-9 col-sm-12">
          <div className="project-description">
            <Card.Title className="detail-heading">Project Description</Card.Title>
            <p>{idoRequest.projectDescription}</p>
            <Card.Title className="detail-heading">Token Description</Card.Title>
            <p>{idoRequest.tokenDescription}</p>
            <Card.Title className="detail-heading">Excluded Countires</Card.Title>
            <ul>
              {idoRequest.countryOrzip.map(country => (
                <li>{country},</li>
                ))}
            </ul>
          </div>  
          <div className="bottom-div">
            <div className="sub-bottom">
              <Card.Title className="detail-heading">Funding Details</Card.Title>
              <div>
                <p>Total Fund Raise</p>
                <span>{idoRequest.totalFundRaise}</span>
              </div>
              <div>
                <p>Total Supply</p>
                <span>{idoRequest.totalSupply}</span>
              </div>
              <div>
                <p>Total Raised till now</p>
                <span>{idoRequest.totalRaised}</span>
              </div>
              <div>
                <p>Supply on Prostarter</p>
                <span>{idoRequest.supplyOnProstarter}</span>
              </div>
              <div>
                <p>Launch Price</p>
                <span>{idoRequest.launchPrice}</span>
              </div>
            </div>
            <div className="sub-bottom last">
              <Card.Title className="detail-heading">Founder Details</Card.Title>
              <div>
                <p>Founder Name</p>
                <span>{idoRequest.founderName}</span>
              </div>
              <div>
                <p>Founder Email</p>
                <span>{idoRequest.founderEmail}</span>
              </div>
              <div>
                <p>Contact</p>
                <span>{idoRequest.founderWhatsapp}</span>
              </div>
              <div>
                <p></p>
                <span></span>
              </div>
              <div>
                <p></p>
                <span></span>
              </div>
            </div>
          
          </div>
        </div> </>}
      </div>
    )
  }
}
const mapStateToProps = state => ({
  user: state.auth,
  idoRequest: state.ido.idoRequest,
});
const mapDispatchToProps = {
  getIdoRquest,
  updatetIdoRquest
};
export default connect(mapStateToProps, mapDispatchToProps)(IDORequest)