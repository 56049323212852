/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-restricted-imports */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Table,Button} from "react-bootstrap";
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import moment from 'moment';
import {getWhiteListApliactions} from '../../../../redux/MainRedux/action';
class WhiteListApplication extends Component {

  state = {
    whiteListArray : [],
    offset: 0,
    limit: 50,
    count: 0,
    search: '',
    pool: '',
    publicProt:false,
    allChecked:true,
    prot: false,
  }
  componentDidMount () {
    const id = this.props.match.params.id;
    
    const cbSuccess = (res) => {
      console.log(res.whitelistEntries)
      this.setState({whiteListArray: res.whitelistEntries,count: res.count,
        pageCount: Math.ceil(res.count / this.state.limit),})
    }
    this.props.getWhiteListApliactions(id,this.state.offset, this.state.limit,this.state.search,this.state.pool, cbSuccess);
  }
  handlePageClick = (data) => {
    const id = this.props.match.params.id;
    const cbSuccess = (res) => {
        console.log(res)
        this.setState({whiteListArray: res.whitelistEntries,count: res.count,
            pageCount: Math.ceil(res.count / this.state.limit),})
    window.scrollTo(0, 0)

      }
    let selected = data.selected;
    this.setState({ offset: selected}, () => {
        this.props.getWhiteListApliactions(id, this.state.offset, this.state.limit,this.state.search,this.state.pool,cbSuccess)
    });
  };
  getByPool = (value) => {
    const {offset, limit,search} = this.state;
    const id = this.props.match.params.id;
    const cbSuccess = (res) => {
      console.log(res)
      this.setState({whiteListArray: res.whitelistEntries,count: res.count,
        pageCount: Math.ceil(res.count / limit),})
  
    }
    this.setState({offset: 0, limit: 50, pool: value}, ()=> {
      this.props.getWhiteListApliactions(id, this.state.offset,limit, search,value,cbSuccess)})

  }
  onSearch = (value) => {
    const { limit, offset, pool} = this.state;
    const { match} = this.props;
    const id =match.params.id;
    const cbSuccess = (res) => {
      console.log(res)
      this.setState({whiteListArray:res.whitelistEntries,count: res.count,
        pageCount: Math.ceil(res.count / this.state.limit),})
  
    }
    this.setState({offset: 0, limit: 50, search:value},
       ()=> this.props.getWhiteListApliactions(id,this.state.offset,limit, value,pool,cbSuccess))

  }
  render() {
    const {whiteListArray, count, limit, allChecked,prot, publicProt} = this.state;
    const {loading} = this.props;
    return (
      <div className="row idos-request">
        <div className="col-md-12"> 
        <input  onChange={e=> this.onSearch(e.target.value)} 
         type="search" placeholder="Search" className="form-control search" />
          
          <div className="row no-gutters first-row">
            <div className="col-4">
              <h5 className="first-h5">Pool</h5>
              <div className="radio-div">
              <label>
                <Checkbox
                  id="all"
                  checked={allChecked}
                  type="checkbox"
                  onChange={(e)=>this.setState({publicProt:false, allChecked: true, prot: false},()=> this.getByPool(''))}
                />
                <div>
                  All
                </div>
              </label>
              <label>
                <Checkbox
                  id="bnb"
                  type="checkbox"
                  checked={prot}
                  onChange={(e)=>this.setState({prot:true, allChecked: false, publicProt: false},()=> this.getByPool('prot'))}
                />
                <div>
                  PROT
                </div>
              </label>
              <label>
                <Checkbox
                  id="eth"
                  checked={publicProt}
                  type="checkbox"
                  onChange={(e)=>this.setState({prot:false, allChecked: false, publicProt: true},()=> this.getByPool('public'))}
                />
                <div>
                  PUBLIC
                </div>
              </label>
             </div>
            </div>
          </div>  
          {whiteListArray.length === 0 && !loading && <h1 className='empty'>No Application Found</h1>}

          <h5 className="applications">{this.props.whiteList && this.props.whiteList.count} <span>Application{(this.props.whiteList.count > 1 ||this.props.whiteList.count === 0 ) && 's'}</span></h5>         
          <Table responsive>
            
            <thead>
              <tr>
                <th>ADDRESS</th>
                <th>EMAIL</th>
                <th>TWEETER</th>
                <th>TELEGRAM</th>
                <th>POOL</th>
                <th>APPLICATION DATE</th>
                <th>PROKING STATUS</th>
              </tr>
            </thead>
            <tbody>
          {whiteListArray.length === 0 && !loading && <h1 className='empty hide-visible'>No Application Found</h1>}
              {whiteListArray.map(whitelist=> (
                <tr>
                  <td>{whitelist.address} </td>
                  <td>{whitelist.email}</td>
                  <td>{whitelist.tweetLink}</td>
                  <td>{whitelist.telegramHandle}</td>
                  <td>{whitelist.pool}</td>
                  <td>{moment(whitelist.createdAt).format('MMM Do YY')}</td>
                  <td>{whitelist.prokingStatus ? 'True': 'False'}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {count > limit && <ReactPaginate
          previousLabel={'previous'}
          nextLabel={'next'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick}
          containerClassName={'pagination'}
          forcePage={this.state.offset}
          activeClassName={'active'}
        />}
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  user: state.auth,
  whiteList: state.ido.whiteList,
  loading: state.ido.loading,
});
const mapDispatchToProps = {
    getWhiteListApliactions
};
export default connect(mapStateToProps, mapDispatchToProps)(WhiteListApplication)