/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-restricted-imports */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Table} from "react-bootstrap";
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
// import Countdown from 'react-countdown';
import Checkbox from 'rc-checkbox';
import {CircularProgress} from "@material-ui/core";
import 'rc-checkbox/assets/index.css';
import {getUsers} from '../../redux/MainRedux/action';
class USERS extends Component {

  state = {
    offset: 0,
    limit: 100,
    count: 0,
    array: [],
    search: ''
  }

  componentDidMount () {
    const {search, limit, offset} = this.state;
    const cbSuccess = (res) => {
      console.log(res)
      this.setState({count: res.count,
        pageCount: Math.ceil(res.count / this.state.limit),})
  
    }
    this.props.getUsers(offset, limit, search, cbSuccess);
  }
  handlePageClick = (data) => {
    const {search, limit, offset} = this.state;
    const cbSuccess = (res) => {
        console.log(res)
        this.setState({count: res.count,
            pageCount: Math.ceil(res.count / limit),})
    window.scrollTo(0, 0)

      }
    let selected = data.selected;
    this.setState({ offset: selected,array:[]}, () => {
        this.props.getUsers( this.state.offset, limit, search,cbSuccess)
    });
  };
  onSearch = (value) => {
    const {search, limit, offset} = this.state;
    const cbSuccess = (res) => {
      console.log(res)
      this.setState({count: res.count,
        pageCount: Math.ceil(res.count / this.state.limit),})
  
    }
    this.setState({offset: 0, limit: 50, search:value}, ()=> this.props.getUsers(this.state.offset,limit, value,cbSuccess))

  }
  render() {
    const {loading,users} = this.props;
    const {count, limit,} = this.state
    return (
      <div className="row idos-request">
        <div className="col-md-12"> 
          <input onChange={e=> this.onSearch(e.target.value)} type="search" placeholder="Search user by address" className="form-control search" />
          {loading && <CircularProgress className="splash-screen-spinner" />}
          <Table responsive>
            <thead>
              <tr>
                <th>ADDRESS</th>
                <th>ISVERIFIED</th>
                <th>PROKING</th>
              </tr>
            </thead>
            <tbody>
              {users && users.users && users.users.map(user=>(
                <tr>
                  <td>{user.address}</td>
                  <td>{user.isVerified ? 'YES': 'NO'}</td>
                  <td>{user.prokingStatus ? 'YES': 'NO'}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {count > limit && <ReactPaginate
          previousLabel={'previous'}
          nextLabel={'next'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          forcePage={this.state.offset}
          onPageChange={this.handlePageClick}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />}
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  user: state.auth,
  users: state.ido.users,
  loading: state.ido.loading
});
const mapDispatchToProps = {
    getUsers
};
export default connect(mapStateToProps, mapDispatchToProps)(USERS)