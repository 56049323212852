/* eslint-disable no-sequences */

import axios from "axios";
class Api {
  static postAxios(route, formData, config) {
    return this.axiosPost(route, formData, config);
  }
  static getAxios(route, formData, config) {
    return this.axiosGet(route, formData, config);
  }
  static putAxios(route, params, config) {
    return this.axiosPut(route, params, config);
  }
  static deleteAxios(route, params, config) {
    return this.axiosDelete(route, params, config);
  }
  static patchAxios(route, params, config) {
    return this.axiosPatch(route, params, config);
  }
  static postRequest = async (endpoint, formData, config) => {
    const url = `${endpoint}`;
    console.log("[URL API]", url, formData, config);
    if (config != undefined && config != null) {
      // with header request
      let options = {
        headers: {
          Authorization: `Bearer ${config.token}`,
        },
      };
      console.log("[delete-axios-header]", options);
      let configration = Object.assign(options);
      return axios
        .post(url, formData, configration)
        .then((response) => {
          console.log("SUCCESS!!", response);
          return response.data;
        })
        .catch((error) => {
          console.log(
            "FAILURE!! with header",
            error.response,
            "error  ===>>>",
            error
          );
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
          return error;
        });
    } else {
      // without header request
      return axios
        .post(url, formData, {
          headers: {
            // Accept: "/",
            "Content-Type": "application/json",
            // 'Access-Control-Allow-Origin': '*',
          },
        })
        .then((response) => {
          console.log("SUCCESS!!", response);
          return response.data;
        })
        .catch((error) => {
          console.log("FAILURE!! without header", error);
          return error.status;
        });
    }
  };

  static axiosGet = async (endpoint, formData, config) => {
    console.log("[get-axios-call]", endpoint, formData, config);
    let url = `${process.env.REACT_APP_API_URL}${endpoint}`;
    console.log("[URL API]", url);
    if (config) {
      // with header request
      let options = {
        responseType: 'arraybuffer',
        headers: {
          Authorization: `Bearer ${config.token}`,
        },
      };
      console.log("[get-axios-header]", options);
      let configration = Object.assign(options);
      return axios
        .get(url, formData, configration)
        .then((response) => {
          console.log("SUCCESS!!", response);
          return response.data;
        })
        .catch((error) => {
          console.log("FAILURE!!", error);
          return error;
        });
    } else {
      // without header request
      return axios
        .get(url, formData)
        .then((response) => {
          console.log("SUCCESS!!", response);
          return response.data;
        })
        .catch((error) => {
          console.log("FAILURE!!", error);
          return error;
        });
    }
  };
  static axiosDelete = async (endpoint, formData, config) => {
    console.log("[delete-axios-call]", endpoint, formData, config);
    let url = `${process.env.REACT_APP_API_URL}${endpoint}`;
    console.log("[URL API]", url);
    if (config) {
      // with header request
      let options = {
        headers: {
          Authorization: `Bearer ${config.token}`,
        },
      };
      console.log("[delete-axios-header]", options);
      let configration = Object.assign(options);
      return axios
        .delete(url, formData, configration)
        .then((response) => {
          console.log("SUCCESS!!", response);
          return response.data;
        })
        .catch((error) => {
          console.log("FAILURE!!", error);
          return error;
        });
    } else {
      // without header request
      return axios
        .delete(url, formData)
        .then((response) => {
          console.log("SUCCESS!!", response);
          return response.data;
        })
        .catch((error) => {
          console.log("FAILURE!!", error);
          return error;
        });
    }
  };
  static axiosPut = async (endpoint, formData, config) => {
    console.log(endpoint, formData,)
    let url = `${process.env.REACT_APP_API_URL}${endpoint}`;
    console.log("[URL API]", url);
    if (config) {
      // with header request
      let options = {
        headers: {
          Authorization: `Bearer ${config.token}`,
        },
      };
      console.log("[delete-axios-header]", options);
      let configration = Object.assign(options);
      return axios
        .put(url, formData, configration)
        .then((response) => {
          console.log("SUCCESS!!", response);
          return response.data;
        })
        .catch((error) => {
          console.log("FAILURE!!", error);
          return error;
        });
    } else {
      // without header request
      return axios
        .put(url, formData)
        .then((response) => {
          console.log("SUCCESS!!", response);
          return response.data;
        })
        .catch((error) => {
          console.log("FAILURE!!", error);
          return error;
        });
    }
  };
  static axiosPatch = async (endpoint, formData, config) => {
    console.log("[edit-axios-call]", endpoint, formData, config);
    let url = `${process.env.REACT_APP_API_URL}${endpoint}`;
    console.log("[URL API]", url);
    if (config) {
      // with header request
      let options = {
        headers: {
          Authorization: `Bearer ${config.token}`,
        },
      };
      console.log("[delete-axios-header]", options);
      let configration = Object.assign(options);
      return axios
        .patch(url, formData, configration)
        .then((response) => {
          console.log("SUCCESS!!", response);
          return response.data;
        })
        .catch((error) => {
          console.log("FAILURE!!", error);
          return error;
        });
    } else {
      // without header request
      return axios
        .patch(url, formData)
        .then((response) => {
          console.log("SUCCESS!!", response);
          return response.data;
        })
        .catch((error) => {
          console.log("FAILURE!!", error);
          return error;
        });
    }
  };
  static axiosPost = async (endpoint, formData, config) => {
    console.log("[delete-axios-call]", endpoint, formData, config);
    let url = `${process.env.REACT_APP_API_URL}${endpoint}`;
    console.log("[URL API]", url);
    if (config) {
      // with header request
      let options = {
        headers: {
          Authorization: `Bearer ${config.token}`,
        },
      };
      console.log("[delete-axios-header]", options);
      let configration = Object.assign(options);
      return axios
        .post(url, formData, configration)
        .then((response) => {
          console.log("SUCCESS!!", response);
          return response.data;
        })
        .catch((error) => {
          console.log("FAILURE!!", error);
          return error;
        });
    } else {
      // without header request
      return axios
        .post(url, formData)
        .then((response) => {
          console.log("SUCCESS!! without header request", response.data);
          return response.data;
        })
        .catch((error) => {
          console.log("FAILURE!!", error);
          return error;
        });
    }
  };
}
export default Api;