import * as TYPES from './types';


// get Idos request
export const getIdosRequest = (offset, limit,projectTitle,status,cbSuccess) => {
    return {
      type: TYPES.GET_IDOS_REQUEST_LOADING,
      offset,
      limit,
      projectTitle,
      status,
     cbSuccess
    };
  };

export const getIdoRquest = (id) => {
    return {
      type: TYPES.GET_IDO_REQUEST_LOADING,
      id,
    };
  };
  export const updatetIdoRquest = (id,status,body,cbSuccess) => {
    return {
      type: TYPES.UPDATE_IDO_REQUEST_LOADING,
      id,
      status,
      body,
     cbSuccess
    };
  };

  //CREATE IDO

  export const createIdo = (body,cbSuccess) => {
    return {
      type: TYPES.CREATE_IDO_LOADING,
      body,
     cbSuccess
    };
  };
  export const updateIdo = (id,body,cbSuccess) => {
    return {
      type: TYPES.UPDATE_IDO_LOADING,
      id,
      body,
     cbSuccess
    };
  };
  export const getIdos = (offset, limit,name,networkId,cbSuccess) => {
    return {
      type: TYPES.GET_IDOS_LOADING,
      offset,
      limit,
      name,
      networkId,
     cbSuccess
    };
  };

  export const getIdo = (id) => {
    return {
      type: TYPES.GET_IDO_LOADING,
      id,
    };
  };

  export const getWhiteListApliactions = (id, offset, limit,search,pool,cbSuccess) => {
    return {
      type: TYPES.GET_WHITELISTAPPLICATION_LOADING,
      id,
      offset,
      limit,
      search,
      pool,
      cbSuccess
    };
  };
  export const getIdoWinners = (id, offset, limit, search,pool,cbSuccess) => {
    return {
      type: TYPES.GET_IDO_WINNERS_LOADING,
      id,
      offset,
      limit,
      search,
      pool,
      cbSuccess
    };
  };
  export const exportIdoWinners = (id,name,cbSuccess) => {
    return {
      type: TYPES.EXPORT_IDO_WINNERS_LOADING,
      id,
      name,
      cbSuccess
    };
  };
  export const sendEmails = (id,body,cbSuccess) => {
    return {
      type: TYPES.EMAIL_TO_IDO_WINNERS_LOADING,
      id,
      body,
      cbSuccess
    };
  };
  export const declareWinners = (id, pool ,cbSuccess) => {
    return {
      type: TYPES.DECLARE_IDO_WINNERS_LOADING,
      id,
     pool,
      cbSuccess
    };
  };
  export const getTotalCounts = (cbSuccess) => {
    return {
      type: TYPES.GET_TOTAL_COUNTS_LOADING,
      cbSuccess
    };
  };
  export const getIdoClaims = (id, offset, limit,vestId ,cbSuccess) => {
    return {
      type: TYPES.GET_IDO_CLAIMS_LOADING,
      id,
      offset,
      limit,
      vestId,
      cbSuccess
    };
  };

  export const getUsers = ( offset, limit ,search,cbSuccess) => {
    return {
      type: TYPES.GET_ALL_USERS_LOADING,
      offset,
      limit,
      search,
      cbSuccess
    };
  };
  export const uploadImage = (body,cbSuccess) => {
    return {
      type: TYPES.UPLOAD_IMAGE_LOADING,
      body,
      cbSuccess
    };
  };
  export const uploadWhitelistImage = (body,cbSuccess) => {
    return {
      type: TYPES.UPLOAD_WHITELIS_IMAGE_LOADING,
      body,
      cbSuccess
    };
  };